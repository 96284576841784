import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type PreviewType = 'modal' | 'quick' | 'slide' | '';
export type PreviewState = {
  previewType: PreviewType;
  result: any;
};
const initialState: PreviewState = {
  previewType: '',
  result: {},
};
const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    openPreview: (state, action: PayloadAction<PreviewState>) => {
      return action.payload;
    },
    closePreview: (state) => {
      return initialState;
    },
  },
});

export const {closePreview, openPreview} = previewSlice.actions;

export const previewReducer = previewSlice.reducer;
