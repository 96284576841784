import React from 'react';
import styled from 'styled-components';
import {ReactComponent as GridIcon} from '../../../assets/images/ic-grid.svg';
import {ReactComponent as TimelineIcon} from '../../../assets/images/ic-timeline.svg';
import {useDispatch} from 'react-redux';
import {isMobileOnly} from 'react-device-detect';
import {useAppSelector} from '../../../reducers';
import {toggleSelectionMode} from '../../../reducers/resultSelectionSlice';
import {Button} from '../../../components/basic/Button';
import {useHistory} from 'react-router-dom';
import {useViewCheck} from './hooks';
import {VIEW_TYPE} from '../../../types/view.types';
import {isVersionFilterSelector} from '../../../reducers/versionSlice';
import {currentPageRegex} from 'helpers/view.helper';

const DisplayModeToggles = styled.div`
  display: flex;
  column-gap: 5px;
`;

function DisplayMode() {
  const [isGridView] = useViewCheck();
  const dispatch = useDispatch();
  const isVersionFilter = useAppSelector(isVersionFilterSelector);
  const docId = useAppSelector((state) => state.versionFilter.docId);
  const searchTerm = useAppSelector((state) => state.versionFilter.searchTerm);
  const history = useHistory();

  if (isMobileOnly) {
    return null;
  }

  const handleChangeView = (viewType: VIEW_TYPE) => () => {
    if (
      (isGridView && viewType === 'grid') ||
      (!isGridView && viewType === 'timeline')
    ) {
      return;
    }

    if (viewType === 'timeline') {
      dispatch(toggleSelectionMode(false));

      if (isVersionFilter) {
        history.goBack();
      } else {
        const newSearch = window.location.search?.replace(currentPageRegex, '');
        window.location.href = '/search/tl' + newSearch;
      }
    } else {
      isVersionFilter
        ? history.push(`/search/v/${docId}/${searchTerm}`)
        : history.push('/search' + window.location.search);
    }
  };

  return (
    <DisplayModeToggles>
      <Button
        onClick={handleChangeView('grid')}
        style={{
          minWidth: 40,
          borderColor: isGridView ? 'var(--primary)' : '',
        }}
        className={isGridView ? 'ic-svg--selected' : 'ic-svg'}
      >
        <GridIcon />
      </Button>
      <Button
        onClick={handleChangeView('timeline')}
        style={{
          minWidth: 40,
          borderColor: !isGridView ? 'var(--primary)' : '',
        }}
        className={!isGridView ? 'ic-svg--selected' : 'ic-svg'}
      >
        <TimelineIcon />
      </Button>
    </DisplayModeToggles>
  );
}

export default DisplayMode;
