import {includes} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useKeycloak} from '@react-keycloak/web';
import {HomePage} from './pages/home-page';
import {SearchPage} from './pages/search';
import {setuserProfile} from './reducers/authSlice';
import PrivateRoute from './routes/PrivateRoute';

const App = () => {
  const {keycloak, initialized} = useKeycloak();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('render', keycloak.authenticated);
    if (keycloak.authenticated) {
      console.log(keycloak);
      keycloak.loadUserInfo().then((profile) => {
        dispatch(
          setuserProfile({
            userInfo: profile,
            authenticated: true,
          }),
        );
      });
    }
    // eslint-disable-next-line
  }, [keycloak.authenticated]);
  if (!initialized) {
    return null;
  }
  if (
    (window.location.pathname === '/search' ||
      includes(window.location.pathname, '/search/v/') ||
      (window.location.pathname === '/search/tl' &&
        window.location.search.includes('q=') &&
        window.location.search.length > 3)) === false
  ) {
    return <HomePage />;
  }

  return (
    <PrivateRoute>
      <SearchPage />
    </PrivateRoute>
  );
};

export default App;
