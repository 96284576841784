import Tippy from '@tippyjs/react';
import React from 'react';

export const SingletonPopper = (props: {source; disabled?: boolean}) => {
  return (
    <Tippy
      singleton={props.source}
      moveTransition="transform 0.4s cubic-bezier(0.22, 1, 0.36, 1)"
      placement="top-start"
      trigger={props.disabled ? '' : 'mouseenter'}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, 2],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              tether: false,
            },
          },
        ],
      }}
    />
  );
};
