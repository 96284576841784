import React from 'react';
import Modal from 'react-modal';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../../reducers';
import {closePreview} from '../../reducers/previewSlice';
import {PreviewContent} from './PreviewContent';

Modal.setAppElement('#root');

export const ResultPreview: React.FC = () => {
  const {previewType, result} = useAppSelector((state) => state.preview);
  const dispatch = useDispatch();

  if (
    (previewType === 'modal' || previewType === 'quick') &&
    isEmpty(result) === false
  ) {
    const isQuickPreview = previewType === 'quick';
    const left = isQuickPreview ? `calc(50% + 175px)` : '50%';

    document.body.style.overflow = 'hidden';
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => dispatch(closePreview())}
        style={{
          content: {
            top: '50%',
            left,
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: ' 0px 0px 30px rgba(0, 0, 0, 0.15)',
            background: '#ffffff',
            borderRadius: 10,
            width: isQuickPreview ? `calc(100% - 390px)` : 950,
            minWidth: 670,
            maxWidth: 950,
            border: 'none',
            padding: 'none',
          },
          overlay: isQuickPreview
            ? {
                background: 'none',
                zIndex: 99,
              }
            : {
                backgroundColor: 'var(--secondary-dark)',
                backdropFilter: 'blur(0.1em)',
                zIndex: 99,
              },
        }}
      >
        <PreviewContent result={result} quick={isQuickPreview} />
      </Modal>
    );
  }

  document.body.style.overflow = '';

  return null;
};
