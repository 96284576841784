import '@szhsin/react-menu/dist/index.css';
import '../../../styles/menu.css';
import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { withSearch } from '@elastic/react-search-ui';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DotsIcon from '../../../assets/images/ic-dots-horizontal.svg';
import EyeIcon from '../../../assets/images/ic-eye.svg';
import TagIcon from '../../../assets/images/ic-tag.svg';
import HistoryIcon from '../../../assets/images/ic-history.svg';
import DownloadIcon from '../../../assets/images/ic-download.svg';
import { Text } from '../../../components/basic/Text';
import { openPreview } from '../../../reducers/previewSlice';
import { isVersionFilterSelector } from '../../../reducers/versionSlice';
import { useAppSelector } from '../../../reducers';
import { toggleManualTagging } from '../../../components/manual-tagging/taggingSlice';
import {
  annotatesCategoriesKey,
  convertArrayToObject,
  getDocumentAnnotated,
  getFirstFieldValue,
} from '../../../helpers/view.helper';
import { documentVersioningConfig, resultCardFields } from 'helpers/getConfigs';
import { TEXT_CONFIG } from 'translations/text';

const IconWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  margin: auto 0;
`;
const ListIconWrapper = styled.div`
  padding: 4px;
  width: 30px;
`;
const CardActionsWithSearch: React.FC<{
  downloadUrl: string;
  result: any;
  searchTerm: string;
  isHide?: boolean;
  // clearQuickPreview: any;
}> = (props) => {
  const selectionMode = useAppSelector(
    (state) => state.resultSelection.selectionMode,
  );
  const isVersionFilter = useAppSelector(isVersionFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  if (props.isHide) return null;
  const openPreviewModal = () => {
    // props.clearQuickPreview();
    dispatch(
      openPreview({
        previewType: 'modal',
        result: props.result,
      }),
    );
  };

  const openManualTaggingModal = () => {
    const annotation = getDocumentAnnotated(
      props.result,
      annotatesCategoriesKey,
    );
    const parsedCategories = convertArrayToObject(annotation);
    return dispatch(
      toggleManualTagging({
        categories: parsedCategories,
        id: getFirstFieldValue(props.result.id),
      }),
    );
  };
  const handleGetDocsByVersion = () => {
    history.push(
      `/search/v/tl/${getFirstFieldValue(props.result.id)}/${props.searchTerm}`,
    );
  };

  if (selectionMode) {
    return (
      <IconWrapper>
        <img src={DotsIcon} alt="more" />
      </IconWrapper>
    );
  }

  return (
    <>
      <Menu
        menuButton={
          <IconWrapper>
            <img src={DotsIcon} alt="more" />
          </IconWrapper>
        }
        direction="bottom"
        align="end"
        styles={{
          minWidth: 'unset',
        }}
        className="b-shadow"
      >
        {isMobile ? null : (
          <MenuItem onClick={openPreviewModal}>
            <ListIconWrapper>
              <img src={EyeIcon} alt="preview" />
            </ListIconWrapper>
            <Text>{TEXT_CONFIG.Preview}</Text>
          </MenuItem>
        )}

        <MenuItem onClick={openManualTaggingModal}>
          <ListIconWrapper>
            <img src={TagIcon} alt="manual-tagging" />
          </ListIconWrapper>
          <Text>{TEXT_CONFIG['Manual tagging']}</Text>
        </MenuItem>

        {!isVersionFilter && documentVersioningConfig?.enabled === 'true' ? (
          <MenuItem onClick={handleGetDocsByVersion}>
            <ListIconWrapper>
              <img src={HistoryIcon} alt="versions" />
            </ListIconWrapper>
            <Text>{TEXT_CONFIG.Versions}</Text>
          </MenuItem>
        ) : (
          <div />
        )}

        <MenuItem
          onClick={() =>
            window.open(
              getFirstFieldValue(props.result?.[resultCardFields.downloadUrl]),
              '_blank',
            )
          }
        >
          <ListIconWrapper>
            <img src={DownloadIcon} alt="download" />
          </ListIconWrapper>
          <Text>{TEXT_CONFIG.Download}</Text>
        </MenuItem>
      </Menu>
    </>
  );
};

export const CardActions = withSearch(({ searchTerm }) => ({ searchTerm }))(
  CardActionsWithSearch,
);
