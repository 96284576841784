import './timeline.css';
import React, {useEffect, useState} from 'react';
import {withSearch} from '@elastic/react-search-ui';
import styled from 'styled-components';
import moment from 'moment';
import {sortBy} from 'lodash';
import {getFieldValue} from '../../../helpers/view.helper';
import {ResultSlidePreview} from './ResultSlidePreview';
import {resultCardFields} from '../../../helpers/getConfigs';
import {getDocsByVersion} from '../../../helpers/middleware';
import {useDispatch} from 'react-redux';
import {setResultsByVersion} from '../../../reducers/versionSlice';
import {useAppSelector} from '../../../reducers';
import {useKeycloak} from '@react-keycloak/web';
import {TEXT_CONFIG} from 'translations/text';
import {Text} from 'components/basic/Text';
import {LoadingComponent} from 'components/basic/Loading';

declare global {
  interface Window {
    TL: any;
  }
}

const BodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  background: white;
  padding-top: 70px;
  @media screen and (max-height: 900px) {
    padding-top: 60px;
  }
`;
const TimelineContainer = styled.div`
  padding-top: 57px;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

const TextContainer = styled.div`
  margin-top: 48px;
  text-align: center;
`;
const TimelineView = ({results, isLoading, match}) => {
  const [curSlide, setCurSlide] = useState();
  const {keycloak} = useKeycloak();
  const dispatch = useDispatch();
  const versionResults = useAppSelector((state) => state.versionFilter.results);
  const curResults = match.params.docId ? versionResults : results;
  const [requestLoading, setLoading] = useState(false);

  const formatToTimelineEvents = () => {
    const orderResultsByTime = sortBy(curResults, (o) =>
      getFieldValue(o?.[resultCardFields.date]),
    );
    return orderResultsByTime.reduce((data, result) => {
      const displayDate = getFieldValue(result?.[resultCardFields.date]);
      if (displayDate) {
        const momentDate = moment(displayDate);
        data.push({
          start_date: {
            year: momentDate.year(),
            month: momentDate.month() + 1,
          },
          text: {
            headline: getFieldValue(result?.[resultCardFields.title]),
            text: getFieldValue(result?.[resultCardFields.content]),
          },
          unique_id: getFieldValue(result.id),
        });
      }
      return data;
    }, []);
  };

  useEffect(() => {
    if (window.TL && curResults.length) {
      setLoading(true);
      const additionalOptions = {
        debug: true,
        scale_factor: 1,
        zoom_sequence: [0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4.5],
        language: 'en',
        timenav_mobile_height_percentage: 0.2,
        timenav_height_min: 250,
        timenav_height: 250,
        marker_width_min: 180,
        optimal_tick_width: 50,
        marker_padding: 8,
      };

      const newFormattedData = formatToTimelineEvents();
      setCurSlide(newFormattedData[0]?.unique_id);

      const newTimeline = new window.TL.Timeline(
        'timeline-embed',
        {
          events: newFormattedData,
        },
        additionalOptions,
      );

      newTimeline._onChange = (data) => {
        if (
          curResults.findIndex(
            (result) => getFieldValue(result.id) === data.unique_id,
          ) !== -1
        ) {
          setCurSlide(data.unique_id);
        }
      };
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [curResults]);

  useEffect(() => {
    if (match.params.docId && match.params.query) {
      setLoading(true);
      getDocsByVersion(
        match.params.docId,
        (resResults: any[]) => {
          dispatch(
            setResultsByVersion({
              results: resResults,
              searchTerm: match.params.query,
              docId: match.params.docId,
            }),
          );
          setLoading(false);
        },
        keycloak?.token,
      );
    }
  }, [match, dispatch, keycloak?.token]);

  return (
    <BodyContainer className="body-container">
      <TimelineContainer>
        <div id="timeline-embed" />
        {isLoading || requestLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <ResultSlidePreview results={curResults} resultId={curSlide} />
            {!curResults?.length && (
              <TextContainer>
                <Text className="result">
                  {TEXT_CONFIG['No results found']}
                </Text>
              </TextContainer>
            )}
          </>
        )}
      </TimelineContainer>
    </BodyContainer>
  );
};

export default withSearch(({results, isLoading}) => ({
  results,
  isLoading,
}))(TimelineView);
