import React, {useEffect, useState} from 'react';
import {withSearch} from '@elastic/react-search-ui';
import styled from 'styled-components';
import {DateInput} from '../basic/DateInput';
import {resultCardFields} from '../../helpers/getConfigs';
import {useAppSelector} from '../../reducers';
import {TEXT_CONFIG} from 'translations/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 290px;
`;
const Title = styled.p`
  color: #000000;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 290px;
`;
const Text = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;

const TimeRange = ({setFilter, filters, isVersionFilter}) => {
  const selectionMode = useAppSelector(
    (state) => state.resultSelection.selectionMode,
  );
  const timeRangeFieldName = resultCardFields.date;
  const timeRangeFilter = filters.find(
    (filter) => filter.field === timeRangeFieldName,
  );
  const [timeRange, setTimeRange] = useState({
    from: timeRangeFilter
      ? timeRangeFilter.values[0].from
      : new Date().toISOString(),
    to: timeRangeFilter
      ? timeRangeFilter.values[0].to
      : new Date().toISOString(),
  });

  useEffect(() => {
    if (timeRangeFilter === undefined) {
      setTimeRange({
        from: timeRangeFilter
          ? timeRangeFilter.values[0].from
          : new Date().toISOString(),
        to: timeRangeFilter
          ? timeRangeFilter.values[0].to
          : new Date().toISOString(),
      });
    }
  }, [timeRangeFilter]);

  const onChangeDate = (type: 'from' | 'to') => (date: Date) => {
    setTimeRange({
      ...timeRange,
      [type]: date.toISOString(),
    });
    setFilter(
      timeRangeFieldName,
      {
        ...timeRange,
        [type]: date.toISOString(),
        name: 'Custom Range',
      },
      'any',
    );
  };

  return (
    <Container>
      <Title>{TEXT_CONFIG['Last modified date']}</Title>
      <Wrapper>
        <DateInput
          selected={new Date(timeRange.from) || ''}
          onChange={onChangeDate('from')}
          maxDate={new Date(timeRange.to)}
          disabled={selectionMode || isVersionFilter}
        />
        <Text>To</Text>
        <DateInput
          selected={new Date(timeRange.to) || ''}
          onChange={onChangeDate('to')}
          popperPlacement="bottom-end"
          minDate={new Date(timeRange.from)}
          maxDate={new Date()}
          disabled={selectionMode || isVersionFilter}
        />
      </Wrapper>
    </Container>
  );
};

export default withSearch(({setFilter, filters}) => ({
  setFilter,
  filters,
}))(TimeRange);
