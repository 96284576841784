import React from 'react';
import styled from 'styled-components';
import {Heading3, Text} from '../basic/Text';
import {SourceTextRow} from '../basic/SourceTextRow';
import {Button} from '../basic/Button';
import DownloadIcon from '../../assets/images/ic-download-light.svg';
import {
  convertWhiteSpaceToBr,
  getAllFieldValues,
  getFirstValue,
} from '../../helpers/view.helper';
import {ResultTags} from '../tags';
import {TEXT_CONFIG} from 'translations/text';

const MainWrapper = styled.div<{showImage?: boolean}>`
  display: ${(props) => (props.showImage ? 'grid' : 'flex')};
  grid-template-columns: ${(props) =>
    props.showImage ? '240px minmax(370px, 650px)' : 'none'};
  grid-column-gap: 30px;
  padding-top: 20px;
`;
const ImageWrapper = styled.div<{imgUrl?: string}>`
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
const PreviewImage = styled.img<{imgUrl?: string}>`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 470px);
  max-width: 240px;
`;
const TitleWrapper = styled.div<{slidePreview?: boolean}>`
  padding: 0 30px 12px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ContentWrapper = styled.div<{slidePreview?: boolean; quick?: boolean}>`
  padding: 15px 30px 0 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  height: ${(props) =>
    props.quick ? '60vh' : props.slidePreview ? 'calc(100vh - 570px)' : '70vh'};
  @media screen and (max-height: 900px) {
    height: ${(props) =>
      props.quick
        ? '60vh'
        : props.slidePreview
        ? 'calc(100vh - 500px)'
        : '70vh'};
  }
`;
const Content = styled.p`
  color: var(--secondary-dark);
  font-size: 14px;
  line-height: 25px;
  margin-top: 20px;
`;
export const PreviewContent = (props: {
  result: any;
  slidePreview?: boolean;
  quick?: boolean;
}) => {
  const {slidePreview, result, quick} = props;
  const {
    titleValue,
    contentValue,
    sourceValue,
    formattedDate,
    imgUrl,
    downloadUrlValue,
  } = getAllFieldValues(result);

  const imgUrlValue = getFirstValue(imgUrl);
  const showImage = Boolean(imgUrlValue) && (quick === false || slidePreview);
  return (
    <>
      <MainWrapper showImage={showImage}>
        {imgUrlValue ? (
          <ImageWrapper
            imgUrl={imgUrlValue}
            className={'result-preview-img ' + (showImage ? '' : 'd-none')}
          >
            <PreviewImage src={imgUrlValue} />
          </ImageWrapper>
        ) : null}

        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <TitleWrapper slidePreview={slidePreview}>
            <Heading3
              dangerouslySetInnerHTML={{
                __html: convertWhiteSpaceToBr(getFirstValue(titleValue)),
              }}
              className="text-overflow result result-title"
            />

            <Button
              variant="primary"
              style={{height: slidePreview ? 32 : ''}}
              onClick={() => window.open(downloadUrlValue, '_blank')}
            >
              <img src={DownloadIcon} alt="download" style={{marginRight: 6}} />
              <Text>{TEXT_CONFIG.Download}</Text>
            </Button>
          </TitleWrapper>

          <ContentWrapper slidePreview={slidePreview} quick={quick}>
            <SourceTextRow name="Source" value={getFirstValue(sourceValue)} />
            <SourceTextRow name={TEXT_CONFIG.Modified} value={formattedDate} />

            <Content
              dangerouslySetInnerHTML={{
                __html: contentValue?.includes('<html')
                  ? contentValue
                  : convertWhiteSpaceToBr(contentValue),
              }}
              className="result-content"
            />
          </ContentWrapper>
        </div>
      </MainWrapper>
      <ResultTags result={result} disabled={!slidePreview} />
    </>
  );
};
