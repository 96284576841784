import {getAppSearchConfigs} from './getConfigs';

function removeName(v) {
  if (v && v.name) {
    // eslint-disable-next-line
    const {name, ...rest} = v;
    return {
      ...rest,
    };
  }

  return v;
}

function rollup(f) {
  const values = f.values.map(removeName).map((v) => ({
    [f.field]: v,
  }));

  return {
    [f.type || 'any']: values,
  };
}

function adaptFilters(filters) {
  if (!filters || filters.length === 0) return {};
  const all = filters.map(rollup);
  return {
    all,
  };
}

export function adaptExportDocumentOverviewRequest(request) {
  const {searchTerm, sortDirection, sortField, selectAll, selection} = request;
  const {facets, result_fields} = getAppSearchConfigs().searchQuery;

  if (selectAll) {
    return {
      front_end_query: {
        query: searchTerm,
        facets,
        result_fields,
        filters: adaptFilters(request.filters),
        sort: sortField ? {[sortField]: sortDirection} : undefined,
      },
      exclude_docs: selection,
    };
  }
  return {
    include_docs: selection,
  };
}
