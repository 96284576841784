import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {WithSearch} from '@elastic/react-search-ui';
import {
  getAllAvailableTags,
  getFieldValue,
  isPropsEqual,
} from '../../helpers/view.helper';
import {useAppSelector} from '../../reducers';
import {Tag} from './Tag';
import {SingletonPopper} from '../basic/Popper';
import {useSingleton} from '@tippyjs/react';
import {useWindowSize} from '../../helpers/hooks';
import {isVersionFilterSelector} from '../../reducers/versionSlice';
import {TEXT_CONFIG} from 'translations/text';

const TagsWrapper = styled.div<{cardResult?: boolean}>`
  display: flex;
  width: fit-content;
  column-gap: 5px;
  overflow: hidden;
  padding-right: 15px;
  ${(props) =>
    props.cardResult
      ? `
    margin-top: 0px;`
      : `
    padding: 12px 30px 30px;
    @media screen and (max-height: 900px) {
      padding-bottom: 12px;
    }`};
`;

const moreTagSpace = 40;

export const ResultTags = React.memo(
  (props: {result: any; cardResult?: boolean; disabled?: boolean}) => {
    const selectionMode = useAppSelector(
      (state) => state.resultSelection.selectionMode,
    );
    const isVersionFillter = useAppSelector(isVersionFilterSelector);
    const [source, target] = useSingleton();
    const [maximumTag, setMaximumTag] = useState<number | null>(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [size] = useWindowSize();

    useEffect(() => {
      if (firstLoad) {
        checkMaxShowableTags();
        setFirstLoad(false);
      } else {
        setMaximumTag(null);
        const timeout = setTimeout(checkMaxShowableTags, 300);
        return () => {
          clearTimeout(timeout);
        };
      }
      // eslint-disable-next-line
    }, [size]);

    const checkMaxShowableTags = () => {
      const container = document.getElementById(elementId);

      if (container) {
        if (container.clientWidth >= container.scrollWidth) {
          return;
        }

        const maxWidth = container.clientWidth - (moreTagSpace + 5);
        let max = 0;
        let totalWidth = 0;
        Object.entries(container.children).every(([, element]) => {
          totalWidth += (element.clientWidth + 5) | 0;
          if (totalWidth >= maxWidth) {
            return false;
          }
          max++;
          return true;
        });

        if (totalWidth >= maxWidth) {
          setMaximumTag(max);
        }
      }
    };

    const defaultTags = getAllAvailableTags(props.result);

    const availableTags = defaultTags;
    const showTags =
      maximumTag === null ? availableTags : availableTags.slice(0, maximumTag);
    const hiddenTags =
      maximumTag === null ? [] : availableTags.slice(maximumTag);

    const elementId = `tag-row-${getFieldValue(props.result.id)}-${Number(
      props.cardResult,
    )}`;

    return (
      <WithSearch
        mapContextToProps={({setFilter}) => ({
          setFilter,
        })}
      >
        {({setFilter}) => {
          return (
            <TagsWrapper cardResult={props.cardResult} id={elementId}>
              <SingletonPopper source={source} disabled={selectionMode} />
              {showTags.map((tag, index) => (
                <Tag
                  key={index}
                  target={target}
                  onClick={() =>
                    props.disabled
                      ? undefined
                      : setFilter(tag.field, tag.value, tag.type || 'all')
                  }
                  disabled={selectionMode || isVersionFillter}
                  color={tag.color}
                  label={TEXT_CONFIG[tag.label] || tag.label}
                >
                  {tag.value}
                </Tag>
              ))}
              {maximumTag !== null && hiddenTags.length ? (
                <Tag
                  key="tag-left"
                  variant="dark"
                  target={target}
                  disabled={selectionMode || isVersionFillter || props.disabled}
                  hiddenTags={hiddenTags}
                >
                  +{availableTags.length - maximumTag}
                </Tag>
              ) : null}
            </TagsWrapper>
          );
        }}
      </WithSearch>
    );
  },
  isPropsEqual,
);
