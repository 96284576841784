import {appSearchConfigs} from '../configs/app-search';
import displayConfigs from '../configs/display-config.json';
import {sampleAppSearchConfigs} from '../configs/sample-app-search/app-search';
import sampleDisplayConfigs from '../configs/sample-app-search/display-config.json';
import tagFieldColorConfig from '../configs/tag-color.json';
import categories from '../configs/sample-categories.json';

import WordIcon from '../assets/images/word-ic.svg';
import ExcelIcon from '../assets/images/excel-ic.svg';
import PdfIcon from '../assets/images/pdf-ic.svg';
import EmailIcon from '../assets/images/email-ic.svg';
import TextIcon from '../assets/images/text-ic.svg';
import PowerPointIcon from '../assets/images/powerpoint-ic.svg';

const usingSampleAppSearch = process.env.REACT_APP_USE_EXAMPLE === 'true';

export const categoriesConfig = JSON.parse(
  (window as any)?._env_?.CATEGORIES_CONFIG || '{}',
);

export const getAppSearchConfigs = (token?: string | undefined) =>
  usingSampleAppSearch ? sampleAppSearchConfigs : appSearchConfigs(token ?? '');

export const ENGINE_NAME = (window as any)?._env_?.ENGINE_NAME || 'knb';

export const SEARCH_PRIVATE_KEY =
  (window as any)?._env_?.SEARCH_PRIVATE_KEY ||
  'private-tsceppofbwnax2vu2e8insdw';

export const {
  resultCardFields,
  filterFacets,
  defaultTags,
  sortingOptions,
} = usingSampleAppSearch
  ? sampleDisplayConfigs
  : (window as any)._env_?.DISPLAY_CONFIG
  ? JSON.parse((window as any)._env_?.DISPLAY_CONFIG || '{}')
  : displayConfigs;

export const annotateCategoriesConfig = (window as any)?._env_
  ?.CATEGORIES_CONFIG
  ? JSON.parse((window as any)?._env_?.CATEGORIES_CONFIG || '{}')
  : categories;

export const tagFieldColor = (window as any)._env_?.TAG_COLOR_CONFIG
  ? JSON.parse((window as any)._env_?.TAG_COLOR_CONFIG || '{}')
  : tagFieldColorConfig;

type TYPE_ICON = {
  type: string;
  icon: string;
};
export const FILETYPE_ICON: TYPE_ICON[] = [
  {
    type: 'PDF',
    icon: PdfIcon,
  },
  {
    type: 'Word',
    icon: WordIcon,
  },
  {
    type: 'E-mail',
    icon: EmailIcon,
  },
  {
    type: 'Text',
    icon: TextIcon,
  },
  {
    type: 'Excel',
    icon: ExcelIcon,
  },
  {
    type: 'Powerpoint',
    icon: PowerPointIcon,
  },
];

export const getIconFromType = (
  type: string | undefined,
  icons: TYPE_ICON[],
) => {
  if (!type) return undefined;
  return icons.find((e) => e.type === type)?.icon || undefined;
};

interface DocumentVersioningConfig {
  enabled: 'false' | 'true';
}
export const documentVersioningConfig = JSON.parse(
  (window as any)?._env_?.DOCUMENT_VERSIONING ?? '{"enabled":"false"}',
) as DocumentVersioningConfig;

export interface FeatureConfig {
  cancelButtonText: string;
  content: string;
  enabled: string;
  okButtonText: string;
  title: string;
}

export const emailOptInConfig = JSON.parse(
  (window as any)?._env_?.EMAIL_OPT_IN ??
    '{"cancelButtonText":"Cancel","content":"Op-in content","enabled":"false","okButtonText":"OK","title":"Op-in"}',
) as FeatureConfig;

export const emailOptOutConfig = JSON.parse(
  (window as any)?._env_?.EMAIL_OPT_OUT ??
    '{"cancelButtonText":"Cancel","content":"Op-in content","enabled":"false","okButtonText":"OK","title":"Op-in"}',
) as FeatureConfig;

export const oneDriveOptInConfig = JSON.parse(
  (window as any)?._env_?.ONEDRIVE_OPT_IN ??
    '{"cancelButtonText":"Cancel","content":"Op-in content","enabled":"false","okButtonText":"OK","title":"Op-in"}',
) as FeatureConfig;

export const oneDriveOptOutConfig = JSON.parse(
  (window as any)?._env_?.ONEDRIVE_OPT_OUT ??
    '{"cancelButtonText":"Cancel","content":"Op-in content","enabled":"false","okButtonText":"OK","title":"Op-in"}',
) as FeatureConfig;

export const ydigitalLogo = `ydigital-logo.svg`;
const imagePath = `${(window as any)?._env_?.CUSTOMER_LOGO}` ?? ydigitalLogo;
export const logo = imagePath;
