import React from 'react';
import styled from 'styled-components';
import {StrongText, Text} from './Text';

export const Wrapper = styled.div<{show?: boolean}>`
  display: inline-flex;
  visibility: ${(props) => (props.show ? '' : 'hidden')};
  position: relative;
  padding-left: 12px;
  & p {
    line-height: 18px;
    color: var(--secondary);
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary);
    height: 100%;
    width: 5px;
  }
`;
export const SourceTextRow = React.memo(
  (props: {name: string; value?: string}) => {
    return (
      <Wrapper show={Boolean(props.value)}>
        <StrongText>{props.name}: </StrongText>
        <Text className="text-overflow" style={{maxWidth: 'calc(100% - 74px)'}}>
          {props.value}
        </Text>
      </Wrapper>
    );
  },
);

export const TextRow = (props: {text: string}) => {
  return (
    <Wrapper show={!!props.text}>
      <Text>{props.text}</Text>
    </Wrapper>
  );
};
