import React, {useEffect, useState} from 'react';
import {SearchProvider} from '@elastic/react-search-ui';
import {useKeycloak} from '@react-keycloak/web';
import App from './App';
import {getAppSearchConfigs} from 'helpers/getConfigs';
import {createConnector} from 'configs/app-search';

export const ESSearchProvider = () => {
  const {keycloak, initialized} = useKeycloak();
  const [apiConnector, setApiConnector] = useState(null);

  useEffect(() => {
    keycloak.onAuthRefreshSuccess = () => {
      setApiConnector(createConnector(keycloak.token));
    };
  }, [keycloak.onAuthRefreshSuccess, keycloak.token]);

  if (!initialized) {
    return null;
  }

  return (
    <SearchProvider config={getAppSearchConfigs(keycloak.token)}>
      <App />
    </SearchProvider>
  );
};
