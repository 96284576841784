import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isEmpty, isEqual, startsWith, xor} from 'lodash';
import {getCategoryObjectFromSelectedTags} from '../../helpers/view.helper';
import {RootState} from '../../reducers';

export interface Categories {
  [key: string]: {
    values: Array<string | number>;
    label?: string;
  };
}
export interface Tag {
  category: string;
  tag: string | number;
}
export interface TaggingState {
  openModal: boolean;
  categories: Categories;
  selectedCate: string;
  selectedTags: Tag[];
  search: string;
  limit: number;
  curDoc: {
    id: string;
    categories: Categories;
  };
}
const initialState: TaggingState = {
  openModal: false,
  categories: {},
  selectedCate: '',
  selectedTags: [],
  search: '',
  limit: 20,
  curDoc: {
    id: '',
    categories: {},
  },
};

const LOAD_MORE_OFFSET = 20;

const taggingSlice = createSlice({
  name: 'tagging',
  initialState,
  reducers: {
    toggleManualTagging: (
      state,
      action: PayloadAction<typeof initialState.curDoc | undefined>,
    ) => {
      state.openModal = !state.openModal;
      state.selectedTags = [];
      state.curDoc = action.payload || {
        id: '',
        categories: {},
      };
      state.selectedCate = Object.keys(state.categories)?.[0] || '';

      if (action.payload && isEmpty(action.payload.categories) === false) {
        Object.entries(action.payload.categories).forEach(
          ([cateName, cateValue]) => {
            cateValue.values?.forEach((tag) => {
              state.selectedTags.push({
                category: cateName,
                tag,
              });
            });
          },
        );
      }
    },
    setCategories: (state, action: PayloadAction<Categories>) => {
      state.categories = action.payload;
      state.selectedCate = Object.keys(action.payload)[0];
    },
    selectCategory: (state, action: PayloadAction<string>) => {
      state.selectedCate = action.payload;
      state.search = '';
    },
    selectTag: (
      state,
      action: PayloadAction<{
        category: string;
        tag: string | number;
      }>,
    ) => {
      state.selectedTags.push(action.payload);
    },
    removeTag: (state, action: PayloadAction<number>) => {
      state.selectedTags = state.selectedTags.filter(
        (tag, index) => index !== action.payload,
      );
    },
    clearSelectedTags: (state) => {
      state.selectedTags = [];
    },
    searchTag: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    loadMoreTags: (state) => {
      state.limit += LOAD_MORE_OFFSET;
    },
  },
});

const categoriesSelector = (state: RootState) => state.tagging.categories;
const selectedCateSelector = (state: RootState) => state.tagging.selectedCate;
const selectedTagsSelector = (state: RootState) => state.tagging.selectedTags;
const searchSelector = (state: RootState) => state.tagging.search;
const limitSelector = (state: RootState) => state.tagging.limit;
const curCategoriesSelector = (state: RootState) =>
  state.tagging.curDoc.categories;

const selectionTagsSelector = createSelector(
  categoriesSelector,
  selectedCateSelector,
  (categories, selectedCateName) => {
    const selectedCate = Object.entries(categories).find(
      ([cateName]) => cateName === selectedCateName,
    );
    return {
      selectedCate: selectedCateName,
      tags: (selectedCate?.[1] || []) as {
        values: Array<string | number>;
      },
    };
  },
);
export const availableSelectionTagsSelector = createSelector(
  selectionTagsSelector,
  selectedTagsSelector,
  searchSelector,
  limitSelector,
  (selectedCategory, selectedTags, search, limit) => {
    const cateSelectedTags: (string | number)[] = [];
    selectedTags.forEach((tag) => {
      if (tag.category === selectedCategory.selectedCate) {
        cateSelectedTags.push(tag.tag);
      }
    });
    const availableTags = xor(selectedCategory.tags.values, cateSelectedTags);
    const filteredAvailableTags = availableTags.filter((tag) => {
      return startsWith(
        String(tag).toLocaleLowerCase(),
        search.toLocaleLowerCase(),
      );
    });
    return {
      selectedCate: selectedCategory.selectedCate,
      tags: filteredAvailableTags.slice(0, limit),
      haveMore: filteredAvailableTags.length > limit,
    };
  },
);

export const isSelectedTagsChangedSelector = createSelector(
  curCategoriesSelector,
  selectedTagsSelector,
  (categories, selectedTags) =>
    isEqual(categories, getCategoryObjectFromSelectedTags(selectedTags)) ===
    false,
);

export const {
  toggleManualTagging,
  setCategories,
  selectCategory,
  selectTag,
  removeTag,
  clearSelectedTags,
  searchTag,
  loadMoreTags,
} = taggingSlice.actions;

export const taggingReducer = taggingSlice.reducer;
