import '@elastic/react-search-ui-views/lib/styles/styles.css';
import './index.css';
import './styles/input.css';
import './styles/button.css';
import './styles/sui-custom.css';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store, {persistor} from './reducers';
import {PersistGate} from 'redux-persist/integration/react';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Keycloak from './configs/keycloak';
import {ESSearchProvider} from './SearchProvider';

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={Keycloak}
    onTokens={(token) => console.log(token)}
    onEvent={(event, error) => console.log(event, error)}
    initOptions={{
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {/* <SearchProvider config={getAppSearchConfigs()}>
            <App />
          </SearchProvider> */}
          <ESSearchProvider />
          <ToastContainer></ToastContainer>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
