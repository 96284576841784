import './toggle.css';
import React from 'react';
export const Toggle = ({checked, onChange}) => {
  return (
    <label style={{marginRight: 4, zIndex: 9999}} className="switch">
      <input onChange={onChange} type="checkbox" checked={checked} />
      <span className="slider round"></span>
    </label>
  );
};
