import 'react-datepicker/dist/react-datepicker.css';
import React, {useRef} from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';
import CalendarIcon from '../../assets/images/ic-calendar.svg';

export const DateInput = React.memo((props: ReactDatePickerProps) => {
  const calendar = useRef<any>();

  return (
    <div style={{position: 'relative', width: 130}}>
      <DatePicker
        ref={calendar}
        dateFormat="dd MMM, yyyy"
        strictParsing
        showMonthDropdown
        showYearDropdown
        showPopperArrow={false}
        todayButton="Today"
        shouldCloseOnSelect
        // adjustDateOnChange
        popperModifiers={{
          preventOverflow: {
            escapeWithReference: false,
            boundariesElement: 'window',
          },
        }}
        {...props}
      />
      <img
        src={CalendarIcon}
        alt="calendar"
        className="calendar-icon"
        onClick={() => calendar?.current?.setOpen(true)}
      />
    </div>
  );
});
