import AppSearchAPIConnector from 'search-ui-app-search-connector-token';

export const API_URL =
  window._env_?.SEARCH_API_URL ||
  window.location.protocol + '//' + window.location.hostname;
export const createConnector = (token) => {
  return new AppSearchAPIConnector({
    /**  Local Search App  */
    searchKey: token,
    engineName: window._env_.ENGINE_NAME || 'knb',
    endpointBase: API_URL,
  });
};

/**
 * See more detail configuration at:
 * https://github.com/elastic/search-ui/blob/master/ADVANCED.md#advanced-configuration
 */

const APPSEARCH_CONFIG = JSON.parse(window._env_.APPSEARCH_CONFIG || '{}');
export const appSearchConfigs = (token) => ({
  apiConnector: createConnector(token),
  ...APPSEARCH_CONFIG,
});
