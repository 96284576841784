import '../manual-tagging.css';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Modal from 'react-modal';
import styled from 'styled-components';
import {CategorySelection} from './CategorySelection.tab';
import {TagSelection} from './TagSelection.tab';
import {SelectedTags} from './SelectedTags.tab';
import {useAppSelector} from '../../../reducers';
import {Heading1} from '../../basic/Text';
import {setCategories} from '../taggingSlice';
import {TEXT_CONFIG} from 'translations/text';
import {annotateCategoriesConfig} from 'helpers/getConfigs';
// import {getManualTaggingList} from '../../../helpers/middleware';

const TitleHeading = styled.div`
  padding: 17px 15px;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const ModalContentWrapper = styled.div`
  display: flex;
  height: 540px;
`;

export const ManualTaggingModal = () => {
  const openModal = useAppSelector((state) => state.tagging.openModal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCategories(annotateCategoriesConfig.annotation));
    // getManualTaggingList();
  }, [dispatch]);

  return (
    <Modal
      isOpen={openModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: ' 0px 0px 30px rgba(0, 0, 0, 0.15)',
          background: '#ffffff',
          borderRadius: 10,
          border: 'none',
          padding: 'none',
          width: 950,
          height: 600,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'var(--secondary-dark)',
          backdropFilter: 'blur(0.1em)',
          zIndex: 99,
        },
      }}
    >
      <TitleHeading>
        <Heading1>{TEXT_CONFIG['Manual tagging']}</Heading1>
      </TitleHeading>

      <ModalContentWrapper className="tagging-modal">
        <CategorySelection />

        <TagSelection />

        <SelectedTags />
      </ModalContentWrapper>
    </Modal>
  );
};
