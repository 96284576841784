import React, {useMemo} from 'react';
import styled from 'styled-components';
import {TEXT_CONFIG} from 'translations/text';
import SearchIcon from '../../assets/images/ic-search.svg';
import {useAppSelector} from '../../reducers';
import {AutoCompleteView} from './AutocompleteView';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 3px;

  input:focus {
    box-shadow: none;
  }
  .sui-search-box:focus {
    outline: 0;
  }
`;
const SearchImage = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  z-index: 1;
`;

export const renderCustomSearchInput = (setSearchTerm, home?: boolean) => ({
  getAutocomplete,
  getInputProps,
  openMenu,
}) => {
  const searchTerm = useAppSelector((state) => state.versionFilter.searchTerm);
  return useMemo(() => {
    const {onChange, value, ...props} = getInputProps();
    // const autoComplete = getAutocomplete();
    const curValue = value || searchTerm || '';
    const onFocus = () => {
      // if (
      //   !autoComplete ||
      //   (autoComplete.props?.allAutocompletedItemsCount === 0 &&
      //     curValue.trim().length)
      // ) {
      //   console.log(curValue);
      //   // setSearchTerm(curValue);
      // }
      openMenu();
    };
    return (
      <InputWrapper
        className={home ? 'search-box-container--home' : 'search-box-container'}
      >
        <SearchImage src={SearchIcon} alt="logo" />
        <input
          {...props}
          placeholder={TEXT_CONFIG['What are you looking for?']}
          onChange={onChange}
          onFocus={onFocus}
          className={home ? 'search-box-input--home' : 'search-box-input'}
          value={curValue}
        />
        <AutoCompleteView searchTerm={curValue} />
        {/* {autoComplete} */}
      </InputWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAutocomplete, getInputProps, openMenu, searchTerm]);
};
