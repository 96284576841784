import {useSingleton} from '@tippyjs/react';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useKeycloak} from '@react-keycloak/web';
import styled from 'styled-components';
import {TEXT_CONFIG} from 'translations/text';
import {updateDocumentMetadata} from '../../../helpers/middleware';
import {
  convertVarNameToLabel,
  getTagFieldColor,
} from '../../../helpers/view.helper';
import {useAppSelector} from '../../../reducers';
import {Button} from '../../basic/Button';
import {SingletonPopper} from '../../basic/Popper';
import {StrongText, Text} from '../../basic/Text';
import {Tag} from '../../tags';
import {
  clearSelectedTags,
  isSelectedTagsChangedSelector,
  removeTag,
  toggleManualTagging,
} from '../taggingSlice';

const SelecteTagsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  position: relative;
`;
const TagListWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 15px;
  overflow: hidden auto;
  flex: 1;
`;
const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 5px;
`;
const ActionsContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`;
export const SelectedTags = () => {
  const selectedTags = useAppSelector((state) => state.tagging.selectedTags);
  const isSelectedTagsChanged = useAppSelector(isSelectedTagsChangedSelector);
  const {keycloak} = useKeycloak();

  const dispatch = useDispatch();
  const [source, target] = useSingleton();

  const handleUpdateManualTagging = async () => {
    if (isSelectedTagsChanged) {
      document.body.style.cursor = 'wait';
      updateDocumentMetadata(keycloak?.token || '', () => {
        closeModal();

        setTimeout(() => {
          document.body.style.cursor = '';
          window.location.reload();
        }, 1000);
      }).finally(() => (document.body.style.cursor = ''));
    }
  };

  const closeModal = () => dispatch(toggleManualTagging());

  return (
    <SelecteTagsWrapper>
      <div className="tagging-tab-title">
        <StrongText>{TEXT_CONFIG['Added tags']}</StrongText>
        {selectedTags.length ? (
          <Tag
            onClick={() => dispatch(clearSelectedTags())}
            style={{
              right: 15,
            }}
          >
            {TEXT_CONFIG['Clear all']}
          </Tag>
        ) : null}
      </div>

      <TagListWrapper className="custom-scrollbar">
        <TagList>
          <SingletonPopper source={source} />
          {selectedTags.map((tag, index) => (
            <Tag
              key={index}
              target={target}
              label={convertVarNameToLabel(tag.category)}
              // variant={getTagFieldColor(tag.category) ? undefined : 'selected'}
              color={'var(--primary)' || getTagFieldColor(tag.category)}
              onClick={() => dispatch(removeTag(index))}
            >
              {tag.tag}
            </Tag>
          ))}
        </TagList>
      </TagListWrapper>

      <ActionsContainer>
        <Button variant="outline" onClick={closeModal}>
          <Text>{TEXT_CONFIG.Cancel}</Text>
        </Button>
        <Button variant="primary" onClick={handleUpdateManualTagging}>
          <Text>{TEXT_CONFIG.Confirm}</Text>
        </Button>
      </ActionsContainer>
    </SelecteTagsWrapper>
  );
};
