import React from 'react';
import styled from 'styled-components';
import {Avatar} from '../../../components/basic/Avatar';
import {SearchBox} from '../../../components/search-box';
import LogoComponent from 'components/basic/Logo';

const HeadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  border-bottom: none;
  white-space: nowrap;
  z-index: 99;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 30px;

  @media screen and (max-width: 414px) {
    padding: 0 12px;
  }
  @media screen and (max-height: 900px) {
    height: 60px;
  }
`;
const SearchBoxWrapper = styled.div`
  flex-grow: 1;
`;
const LogoWrapper = styled.div`
  padding-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media screen and (max-width: 414px) {
    padding-right: 12px;
  }
`;

const SearchHeading = () => {
  return (
    <HeadingContainer>
      <FlexContainer>
        <LogoWrapper
          onClick={() => {
            window.location.href = '/';
          }}
        >
          <LogoComponent styles={{height: 50}} />
        </LogoWrapper>

        <SearchBoxWrapper>
          <SearchBox />
        </SearchBoxWrapper>

        <Avatar />
      </FlexContainer>
    </HeadingContainer>
  );
};

export default SearchHeading;
