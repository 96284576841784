import React from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router-dom';
import {SearchHeading} from './search-heading';
import {ResultHeader} from './result-header';
import {GridView, GridViewWithVersion} from './grid-view';
import {TimelineView} from './timeline-view';
import {ResultPreview} from '../../components/result-preview';
import {ExportButton} from '../../components/basic/Button';
import {ManualTaggingModal} from '../../components/manual-tagging';

const PageContainer = styled.div`
  flex-grow: 1;
`;

const SearchPage = () => {
  return (
    <PageContainer>
      <SearchHeading />

      <ResultHeader />
      <Switch>
        <Route path="/search/v/tl/:docId/:query" component={TimelineView} />
        <Route path="/search/tl" component={TimelineView} />
        <Route path="/search/v/:docId/:query" component={GridViewWithVersion} />
        <Route component={GridView} />
      </Switch>

      <ResultPreview />
      <ExportButton />
      <ManualTaggingModal />
    </PageContainer>
  );
};

export default SearchPage;
