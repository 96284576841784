import React from 'react';
import {withSearch} from '@elastic/react-search-ui';
import {isMobileOnly} from 'react-device-detect';
import {numberWithCommas} from '../../../helpers/view.helper';
import {Text} from '../../../components/basic/Text';
import {useViewCheck} from './hooks';
import {useAppSelector} from '../../../reducers';
import {TEXT_CONFIG} from 'translations/text';

const PageInfo = withSearch(
  ({
    wasSearched,
    resultsPerPage,
    current,
    totalResults,
    resultSearchTerm,
    error,
  }) => ({
    wasSearched,
    resultsPerPage,
    current,
    totalResults,
    resultSearchTerm,
  }),
)((props) => {
  const {
    wasSearched,
    resultsPerPage,
    current,
    totalResults,
    resultSearchTerm,
    error,
  } = props;

  const [isGridView] = useViewCheck();
  const searchTerm = useAppSelector((state) => state.versionFilter.searchTerm);
  const results = useAppSelector((state) => state.versionFilter.results);
  const isVersionFilter = Boolean(results.length && searchTerm);
  const isSearched = (isGridView && wasSearched) || isVersionFilter;

  if (error) {
    let errMsg = '';
    if (error.includes('403')) {
      errMsg = TEXT_CONFIG['Forbidden!'];
    } else if (error.includes('500')) {
      errMsg = TEXT_CONFIG['Server Error!'];
    }
    return <Text className="result">{errMsg}</Text>;
  }
  if (!isSearched || !isGridView) {
    return null;
  }
  if (isVersionFilter) {
    return (
      <Text className="result">
        <b>{numberWithCommas(results.length)}</b>{' '}
        {TEXT_CONFIG['version(s) for the selected document.']}
      </Text>
    );
  }
  if (totalResults === 0) {
    return (
      <Text className="result">
        {TEXT_CONFIG['No results found']}{' '}
        {!isMobileOnly ? (
          <>
            {TEXT_CONFIG.for}{' '}
            <em className="result-search-term">{resultSearchTerm}</em>
          </>
        ) : null}
      </Text>
    );
  }

  const endPage =
    current * resultsPerPage < totalResults
      ? current * resultsPerPage
      : totalResults;

  return (
    <Text className="result">
      <b>
        {1} - {endPage}
      </b>{' '}
      {TEXT_CONFIG.of} <b>{numberWithCommas(totalResults)}</b>{' '}
      {TEXT_CONFIG['result(s)']}{' '}
      {!isMobileOnly ? (
        <>
          {TEXT_CONFIG.for}{' '}
          <em className="result-search-term">{resultSearchTerm}</em>
        </>
      ) : null}
    </Text>
  );
});

export default PageInfo;
