import {useKeycloak} from '@react-keycloak/web';

const PrivateRoute = ({children}) => {
  const {keycloak} = useKeycloak();
  if (!keycloak.authenticated) {
    window.location.href = keycloak.createLoginUrl();
    return null;
  }
  return children;
};

export default PrivateRoute;
