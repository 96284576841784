import './tag.css';
import 'tippy.js/dist/tippy.css';
import React from 'react';
import styled from 'styled-components';
import Tippy, {useSingleton} from '@tippyjs/react';
import {WithSearch} from '@elastic/react-search-ui';
import {Text} from '../basic/Text';
import {isPropsEqual} from '../../helpers/view.helper';

const Wrapper = styled.div<{color?: string}>`
  position: relative;
  border-radius: 12px;
  padding: 0 10px;
  height: 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s ease;
  & p {
    color: ${(props) => props.color || 'rgba(0, 0, 0, 0.4)'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    z-index: 1;
  }
  &:active {
    background: rgba(24, 144, 255, 0.05);
    & p {
      color: ${(props) => props.color || 'var(--primary)'};
    }
  }
  &:before {
    content: '';
    background-color: ${(props) => props.color || 'var(--secondary-light)'};
    opacity: ${(props) => (props.color ? 0.1 : 0.8)};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 0;
  }
  &:hover:before {
    opacity: ${(props) => (props.color ? 0.3 : 1)};
  }
`;
interface Props {
  children: any;
  label?: string;
  onClick?: any;
  disabled?: boolean;
  color?: string;
  variant?: 'outline' | 'selected' | 'dark';
  style?: React.CSSProperties;
  target?: any;
  hiddenTags?: any[];
}
export const Tag = React.memo((props: Props) => {
  const {
    children,
    label,
    onClick,
    disabled,
    color,
    variant,
    style,
    target,
    hiddenTags,
  } = props;

  const renderContent = () => {
    return (
      <Wrapper
        onClick={disabled ? undefined : onClick}
        color={color}
        className={variant ? 'tag-' + variant : ''}
        style={style}
      >
        <Text>{children}</Text>
      </Wrapper>
    );
  };

  if (hiddenTags) {
    return (
      <Tippy
        content={<HiddenTags tags={hiddenTags} />}
        trigger={disabled ? '' : 'click'}
        interactive
        placement="bottom"
        theme="light"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [10, 2],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                tether: false,
              },
            },
          ],
        }}
      >
        {renderContent()}
      </Tippy>
    );
  }

  if (label) {
    return (
      <Tippy content={label} singleton={target}>
        {renderContent()}
      </Tippy>
    );
  }

  return renderContent();
}, isPropsEqual);

const PopperContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 8px;
  min-width: 100px;
  max-width: 500px;
  max-height: 170px;
  padding: 8px;
  overflow-y: auto;
`;
const HiddenTags = (props: {tags: any[]}) => {
  const [source, target] = useSingleton();
  return (
    <WithSearch
      mapContextToProps={({setFilter}) => ({
        setFilter,
      })}
    >
      {({setFilter}) => {
        return (
          <PopperContentWrapper className="custom-scrollbar">
            <Tippy
              singleton={source}
              moveTransition="transform 0.4s cubic-bezier(0.22, 1, 0.36, 1)"
              placement="top-start"
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [10, 2],
                    },
                  },
                  {
                    name: 'preventOverflow',
                    options: {
                      altAxis: true,
                      tether: false,
                    },
                  },
                ],
              }}
            />
            {props.tags.map((hiddenTag, index) => (
              <Tag
                key={index}
                target={target}
                color={hiddenTag.color}
                label={hiddenTag.label}
                onClick={() =>
                  setFilter(hiddenTag.field, hiddenTag.value, 'any')
                }
              >
                {hiddenTag.value}
              </Tag>
            ))}
          </PopperContentWrapper>
        );
      }}
    </WithSearch>
  );
};
