import styled from 'styled-components';

export const Heading1 = styled.span`
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
`;

export const Heading2 = styled.span`
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`;

export const Heading3 = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 14px;
`;

export const StrongText = styled.p`
  font-size: 14px;
  line-height: 25px;
  font-weight: bold;
  margin-right: 2px;
`;

export const Link = styled.a`
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 14px;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;

  :hover {
    /* text-decoration: underline; */
    color: #1890ff;
  }
`;
