import React, {useState} from 'react';
import styled from 'styled-components';
import {Facet} from '@elastic/react-search-ui';
import DownIcon from '../../assets/images/ic-down.svg';
import UpIcon from '../../assets/images/ic-up.svg';
import {
  appendClassName,
  getFilterValueDisplay,
} from '../../helpers/view.helper';
import {Heading2} from '../basic/Text';
import {IconButton} from '../basic/Button';
import {useAppSelector} from '../../reducers';
import {TEXT_CONFIG} from 'translations/text';

export const CollapsibleFacet = (props: any) => {
  const [collapse, setCollapse] = useState(false);
  const selectionMode = useAppSelector(
    (state) => state.resultSelection.selectionMode,
  );
  return (
    <Facet
      {...props}
      show={4}
      view={MultiCheckboxFacet(collapse, setCollapse, selectionMode)}
    />
  );
};

const FilterContent = styled.fieldset<{collapse: boolean}>`
  display: ${(props) => (props.collapse ? 'none' : undefined)};
  border: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  transition: all 0.3s ease;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InputOptionWrapper = styled.div<{isSelectionMode: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${(props) => (props.isSelectionMode ? 'none' : 'auto')};
`;

const MultiCheckboxFacet = (
  collapse: boolean,
  setCollapse: any,
  selectionMode: boolean,
) => (props: {
  className: any;
  label: any;
  onMoreClick: any;
  onRemove: any;
  onSelect: any;
  options: any;
  showMore: any;
  showSearch: any;
  onSearch: any;
  searchPlaceholder: any;
}) => {
  const {
    className,
    label,
    onMoreClick,
    onRemove,
    onSelect,
    options,
    showMore,
    showSearch,
    onSearch,
    searchPlaceholder,
  } = props;
  console.log({selectionMode});
  return (
    <div className={appendClassName('sui-facet', className)}>
      <Header>
        <Heading2>{TEXT_CONFIG[label]}</Heading2>

        <IconButton
          style={{
            cursor: selectionMode ? 'auto' : 'pointer',
          }}
          selectionMode={selectionMode}
          disabled={selectionMode}
          onClick={() => setCollapse(!collapse)}
          icon={<img src={collapse ? DownIcon : UpIcon} alt="collapse" />}
        />
      </Header>
      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={searchPlaceholder || 'Search'}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
      )}

      <FilterContent collapse={collapse}>
        <div className="sui-multi-checkbox-facet">
          {options.length < 1 && (
            <div>{TEXT_CONFIG['No matching options']}</div>
          )}
          {options.map(
            (
              option: {
                selected: any;
                value: any;
                count: {toLocaleString: (arg0: string) => React.ReactNode};
              },
              index: number,
            ) => (
              <CustomOption
                key={index}
                option={option}
                label={label}
                onRemove={onRemove}
                onSelect={onSelect}
              />
            ),
          )}
        </div>
        <CustomShowMore showMore={showMore} onMoreClick={onMoreClick} />
      </FilterContent>
    </div>
  );
};

const CustomShowMore = ({showMore, onMoreClick}) => {
  const selectionMode = useAppSelector(
    (state) => state.resultSelection.selectionMode,
  );

  return showMore ? (
    <button
      type="button"
      className="sui-facet-view-more"
      onClick={selectionMode ? undefined : onMoreClick}
      aria-label="Show more options"
      style={{
        color: selectionMode ? 'var(--secondary)' : '',
        cursor: selectionMode ? 'auto' : 'pointer',
      }}
    >
      + <div style={{marginLeft: 20}}>{TEXT_CONFIG.More}</div>
    </button>
  ) : null;
};

const CustomOption = ({option, label, onRemove, onSelect}) => {
  const checked = option.selected;
  const selectionMode = useAppSelector(
    (state) => state.resultSelection.selectionMode,
  );

  return (
    <InputOptionWrapper isSelectionMode={selectionMode}>
      <input
        id={`${label}_${getFilterValueDisplay(option.value)}`}
        type="checkbox"
        checked={checked}
        onChange={() =>
          checked ? onRemove(option.value) : onSelect(option.value)
        }
        disabled={selectionMode}
      />

      <label
        key={`${getFilterValueDisplay(option.value)}`}
        htmlFor={`${label}_${getFilterValueDisplay(option.value)}`}
        className="sui-multi-checkbox-facet__option-label"
      >
        <span
          className="sui-multi-checkbox-facet__input-text"
          style={{
            color: selectionMode ? 'var(--secondary)' : '',
          }}
        >
          {getFilterValueDisplay(option.value)}
        </span>
      </label>

      <span className="sui-multi-checkbox-facet__option-count">
        {option.count.toLocaleString('en')}
      </span>
    </InputOptionWrapper>
  );
};
