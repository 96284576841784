import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Input} from '../../basic/Input';
import {StrongText, Text} from '../../basic/Text';
import SearchIcon from '../../../assets/images/ic-search.svg';
import {Tag} from '../../tags';
import {useAppSelector} from '../../../reducers';
import {convertVarNameToLabel} from '../../../helpers/view.helper';
import {
  availableSelectionTagsSelector,
  selectTag,
  searchTag,
  loadMoreTags,
} from '../taggingSlice';
import {TEXT_CONFIG} from 'translations/text';

const TagSelectionWrapper = styled.div`
  width: 500px;
  padding: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
`;
const TagListWrapper = styled.div`
  padding: 8px 15px 8px 0;
  flex: 1;
  overflow: hidden auto;
`;
const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 5px;
`;
export const TagSelection = () => {
  const {selectedCate, tags, haveMore} = useAppSelector((state) =>
    availableSelectionTagsSelector(state),
  );
  const search = useAppSelector((state) => state.tagging.search);
  const dispatch = useDispatch();

  return (
    <TagSelectionWrapper>
      <div className="tagging-tab-title">
        <StrongText>{convertVarNameToLabel(selectedCate)}</StrongText>
      </div>

      <Input
        startIcon={
          <img
            src={SearchIcon}
            alt="search"
            style={{
              marginTop: 3,
            }}
          />
        }
        containerStyle={{
          paddingRight: 15,
          margin: '8px 0',
        }}
        value={search}
        onChange={(event) => dispatch(searchTag(event.target.value))}
      />
      <TagListWrapper className="custom-scrollbar">
        <TagListWrapper className="custom-scrollbar">
          <TagList>
            {tags.map((tag, index) => (
              <Tag
                key={index}
                variant="outline"
                onClick={() =>
                  dispatch(
                    selectTag({
                      category: selectedCate,
                      tag,
                    }),
                  )
                }
              >
                {tag}
              </Tag>
            )) || null}
          </TagList>
          {haveMore && (
            <div className="flex-center">
              <Text
                style={{
                  color: 'var(--primary)',
                  cursor: 'pointer',
                  lineHeight: '25px',
                  marginTop: 20,
                }}
                onClick={() => dispatch(loadMoreTags())}
              >
                {TEXT_CONFIG['Load more']}
              </Text>
            </div>
          )}
        </TagListWrapper>
      </TagListWrapper>
    </TagSelectionWrapper>
  );
};
