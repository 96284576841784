import React from 'react';

export const LoadingComponent = () => {
  return (
    <div className="flex-center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
