import React, {InputHTMLAttributes, ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;
`;
const StyledInput = styled.input<{startIcon?: boolean}>`
  width: 100%;
  height: 40px;
  padding-left: ${(props) => (props.startIcon ? '40px' : '')};
`;
const StartIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  z-index: 1;
`;
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  startIcon?: ReactNode;
  containerStyle?: React.CSSProperties;
}
export const Input: React.FC<Props> = React.memo(
  ({startIcon, containerStyle, ...props}) => {
    return (
      <Container style={containerStyle}>
        {startIcon ? <StartIcon>{startIcon}</StartIcon> : null}
        <StyledInput {...props} startIcon={Boolean(startIcon)} />
      </Container>
    );
  },
);
