import React from 'react';
import {filterFacets} from '../../helpers/getConfigs';
import {CollapsibleFacet} from './CollapsibleFacet';

export const FacetList = () => {
  return (
    <>
      {filterFacets.map((facet, key) => (
        <CollapsibleFacet key={key} {...facet} />
      ))}
    </>
  );
};
