import {useKeycloak} from '@react-keycloak/web';
import {fetchSuggestion} from 'helpers/middleware';
import {getFieldValue} from 'helpers/view.helper';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';

export const AutoCompleteView = ({searchTerm}) => {
  const {keycloak} = useKeycloak();
  const [autocompletedSuggestions, setSuggestion] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceDropDown = useCallback(
    debounce(
      (nextValue) =>
        fetchSuggestion({
          token: keycloak.token,
          searchTerm: nextValue,
          callback: (data) => {
            setSuggestion(data);
          },
        }),
      500,
    ),
    [],
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (searchTerm) {
      debounceDropDown(searchTerm);
    }
  }, [searchTerm, keycloak.token, debounceDropDown]);

  if (
    !searchTerm ||
    !autocompletedSuggestions ||
    !(autocompletedSuggestions as any)?.results?.length
  )
    return null;

  return (
    <div
      style={{padding: 16, maxHeight: 450, overflowY: 'auto'}}
      className="sui-search-box__autocomplete-container"
    >
      {(autocompletedSuggestions as any)?.results?.map((e, i) => (
        <div key={i}>
          <div style={{margin: '8px 0'}}>
            <strong style={{fontSize: 14}}>
              {getFieldValue(e?.title) || e?.title}
            </strong>
          </div>

          {e?.results?.map((result, index) => {
            return (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: (getFieldValue(result?.title) || '') as string,
                }}
                onClick={() =>
                  (window.location.href =
                    '/search?q=' +
                      (getFieldValue(result?.title) as string)
                        ?.replaceAll('<em>', '')
                        ?.replaceAll('</em>', '') || '')
                }
                className="suggestions flex"
              ></div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
