import React from 'react';
import {withSearch} from '@elastic/react-search-ui';
import {useDispatch} from 'react-redux';
import {isMobileOnly} from 'react-device-detect';
import {useAppSelector} from '../../../reducers';
import {
  toggleSelectAllResults,
  toggleSelectionMode,
} from '../../../reducers/resultSelectionSlice';
import {Checkbox} from '../../../components/basic/Checkbox';
import {Button} from '../../../components/basic/Button';
import {Text} from '../../../components/basic/Text';
import {useViewCheck} from './hooks';
import {isVersionFilterSelector} from '../../../reducers/versionSlice';
import {TEXT_CONFIG} from 'translations/text';

const SelectionMode = withSearch(({wasSearched, totalResults}) => ({
  wasSearched,
  totalResults,
}))((props) => {
  const {wasSearched, totalResults} = props;

  const {selection, selectionMode, selectAll} = useAppSelector(
    (state) => state.resultSelection,
  );
  const isVersionFilter = useAppSelector(isVersionFilterSelector);

  const dispatch = useDispatch();

  const [isGridView] = useViewCheck();

  if (totalResults === 0) {
    return null;
  }

  // Selection mode should only be used when the user is using the grid view
  if (!isGridView) {
    return null;
  }

  // The user cannot select results on mobile
  if (isMobileOnly) {
    return null;
  }

  return (
    <>
      {selectionMode ? (
        <Checkbox
          label={
            selection.length || selectAll
              ? TEXT_CONFIG['Deselect all']
              : TEXT_CONFIG['Select all']
          }
          containerStyles={{
            marginRight: 8,
            minWidth: 100,
          }}
          onChange={() => dispatch(toggleSelectAllResults())}
          checked={selectAll}
        />
      ) : null}

      {wasSearched && !isVersionFilter ? (
        <Button
          onClick={() => dispatch(toggleSelectionMode())}
          variant={selectionMode ? 'secondary' : 'outline'}
          className={selectionMode ? '' : 'button-primary-contained'}
        >
          <Text>
            {selectionMode ? TEXT_CONFIG.Cancel : TEXT_CONFIG['Select Mode']}
          </Text>
        </Button>
      ) : null}
    </>
  );
});

export default SelectionMode;
