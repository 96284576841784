import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AvatarImage from '../../assets/images/sample-avatar.png';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import MoonIcon from '../../assets/images/ic-moon.svg';
import HelpIcon from '../../assets/images/ic-help.svg';
import InfoIcon from '../../assets/images/ic-info.svg';
import PowerIcon from '../../assets/images/ic-power.svg';
import { Text, StrongText, Heading1 } from './Text';
import { useKeycloak } from '@react-keycloak/web';
import { Button } from './Button';
import { useAppSelector } from '../../reducers';
import { selectUserInfo, setuserProfile } from '../../reducers/authSlice';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TEXT_CONFIG } from 'translations/text';
import { Toggle } from './toggle/Toggle';
import { fetchEmailAndOneDriveInfo, setEmailSetting } from 'helpers/middleware';
import Modal from 'react-modal';
import {
  FeatureConfig,
  emailOptInConfig,
  emailOptOutConfig,
  oneDriveOptInConfig,
  oneDriveOptOutConfig,
} from 'helpers/getConfigs';

Modal.setAppElement('#root');
const Wrapper = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  user-select: none;
`;
const ListIcon = styled.img`
  padding: 4px;
  padding-right: 4px;
`;

interface MailBox {
  address: string;
  'opt-in': boolean;
}

interface Drive {
  driveId: string;
  'opt-in': boolean;
  driveName?: string;
}
interface Consent {
  mailboxes: MailBox[];
  drives: Drive[];
}

const REFRESH_TIMEOUT = 3000;
export const Avatar = () => {
  // const {loggedIn} = useAppSelector((state) => state.auth);
  // const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const history = useHistory();
  const [consentData, setConsentData] = useState<Consent | undefined>(
    undefined,
  );
  const [currentFocusItem, setItem] = useState<MailBox | Drive | undefined>(
    undefined,
  );
  const [isConfirmModalVisible, setModalVisible] = useState(false);
  const { userInfo } = useAppSelector(selectUserInfo);
  const [popupData, setPopupData] = useState<FeatureConfig | undefined>(
    undefined,
  );
  const fetchSetting = async () => {
    await fetchEmailAndOneDriveInfo({
      token: keycloak.token,
      userId: userInfo?.email,
      callback: (data) => {
        console.log({ data });
        if (data) {
          setConsentData(data);
        }
      },
    });
  };
  useEffect(() => {
    if (keycloak?.token && userInfo?.email) {
      fetchSetting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token, userInfo?.email]);
  if (!keycloak.authenticated) {
    return (
      <Button
        onClick={() => {
          window.location.href = keycloak.createLoginUrl();
        }}
      >
        <Text>{TEXT_CONFIG['Sign in']}</Text>
      </Button>
    );
  }
  const handleEmailSetting = () => {
    if (keycloak?.token && currentFocusItem) {
      const toggleValue = (currentFocusItem as any)?.address
        ? {
          mailbox: (currentFocusItem as any)?.address,
        }
        : {
          driveId: (currentFocusItem as any)?.driveId,
        };
      const data = {
        ...toggleValue,
        userId: userInfo?.email,
        consent: !currentFocusItem?.['opt-in'] || false,
      };
      setEmailSetting({
        token: keycloak?.token,
        data,
        callBack: () => {
          setTimeout(() => fetchSetting(), REFRESH_TIMEOUT);
        },
      });
    }
  };
  return (
    <>
      <Menu
        menuButton={
          <Wrapper>
            <img src={AvatarImage} alt="avatar" width={40} height={40} />
          </Wrapper>
        }
        direction="bottom"
        align="end"
        styles={{
          minWidth: 'unset',
        }}
        className="b-shadow"
        offsetY={4}
      >
        <MenuItem disabled>
          <StrongText
            style={{
              color: 'var(--primary)',
            }}
          >
            {TEXT_CONFIG['Hi,'] + userInfo?.preferred_username || ''}
          </StrongText>
        </MenuItem>
        <MenuDivider />
        {(consentData?.mailboxes ?? [])?.map((email) => {
          const data = !email?.['opt-in']
            ? emailOptInConfig
            : emailOptOutConfig;
          return (
            <MenuItem
              disabled={data?.enabled === 'false'}
              key={email?.address}
              onClick={(e) => {
                e.stopPropagation = true;
                // Keep the menu open after this menu item is clicked
                e.keepOpen = true;
                setItem(email);
                if (data?.enabled !== 'false') {
                  setPopupData(data);
                  setModalVisible(true);
                }
              }}
              styles={{ marginBottom: 4 }}
            >
              <Toggle
                checked={email?.['opt-in'] || false}
                onChange={() => {
                  console.log('');
                }}
              />
              <Text>{email?.address || ''}</Text>
            </MenuItem>
          );
        })}
        {(consentData?.drives || [])?.map((drive) => {
          const data = !drive?.['opt-in']
            ? oneDriveOptInConfig
            : oneDriveOptOutConfig;
          return (
            <MenuItem
              // disabled={!keycloak?.token || !userInfo?.email}
              styles={{ marginBottom: 4 }}
              key={drive?.driveId}
              disabled={data?.enabled === 'false'}
              onClick={(e) => {
                e.stopPropagation = true;
                // Keep the menu open after this menu item is clicked
                e.keepOpen = true;
                setItem(drive);
                if (data?.enabled !== 'false') {
                  setPopupData(data);
                  setModalVisible(true);
                }
              }}
            >
              <Toggle
                checked={drive?.['opt-in'] || false}
                onChange={() => {
                  console.log('');
                }}
              />
              <Text>{drive?.driveName ?? 'OneDrive'}</Text>
            </MenuItem>
          );
        })}

        <MenuItem>
          <ListIcon src={MoonIcon} alt="theme-mode" />
          <Text>{TEXT_CONFIG['Dark Mode:']} </Text>
        </MenuItem>
        <MenuItem>
          <ListIcon src={HelpIcon} alt="help-center" />
          <Text>{TEXT_CONFIG['Help center']}</Text>
        </MenuItem>
        <MenuItem onClick={() => window.open('https://y.digital/', '_blank')}>
          <ListIcon src={InfoIcon} alt="about-us" />
          <Text>{TEXT_CONFIG['About us']}</Text>
        </MenuItem>
        <MenuDivider />
        <MenuItem
          styles={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            history.push('/');
            dispatch(
              setuserProfile({
                userInfo: {},
                authenticated: false,
              }),
            );
            keycloak.logout();
          }}
        >
          <Text>{TEXT_CONFIG['Log Out']}</Text>
          <img src={PowerIcon} alt="log-out" />
        </MenuItem>
      </Menu>
      {isConfirmModalVisible && (
        <ConfirmModal
          handleAction={() => {
            setModalVisible(false);
            handleEmailSetting();
          }}
          content={popupData}
          isVisible={isConfirmModalVisible}
          closeModal={() => setModalVisible(false)}
        />
      )}
    </>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '80%',
    maxHeight: '70%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'var(--secondary-dark)',
    zIndex: 9999,
  },
  zIndex: 9999,
};
const ConfirmModal = ({ closeModal, isVisible, content, handleAction }) => {
  return content ? (
    <Modal isOpen={isVisible} onRequestClose={closeModal} style={customStyles}>
      <div style={{ flexDirection: 'column' }} className="flex-center">
        <div style={{ textAlign: 'center' }}>
          <Heading1>{content?.title || ''}</Heading1>
          <div style={{ marginTop: 16, whiteSpace: 'pre-wrap' }}>
            {content?.content || ''}
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <Button
            style={{ marginRight: 8 }}
            onClick={handleAction}
            variant="primary"
          >
            <Text>{content?.okButtonText || ''}</Text>
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={closeModal}>
            <Text> {content?.cancelButtonText || ''}</Text>
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};
