import React from 'react';
import styled from 'styled-components';
import {TEXT_CONFIG} from 'translations/text';
import CloseIcon from '../../assets/images/ic-close.svg';
import {resultCardFields} from '../../helpers/getConfigs';
import {Filter} from '../../types/filter.type';

const Wrapper = styled.div`
  border: 1px solid var(--primary);
  background: rgba(24, 144, 255, 0.03);
  border-radius: 3px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  user-select: none;
  height: 20px;
`;
const Key = styled.span`
  color: #1890ff;
  font-size: 13px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;
const CloseIconWrapper = styled.div`
  height: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
interface KeywordsProps {
  filter: Filter;
  removeFilter: any;
}
export const Keywords: React.FC<KeywordsProps> = (props) => {
  return (
    <React.Fragment>
      {props.filter.values.map((keyword, index) => (
        <KeywordTag
          key={index}
          keyword={keyword}
          field={props.filter.field}
          removeFilter={props.removeFilter}
        />
      ))}
    </React.Fragment>
  );
};

interface KeywordTagProps {
  keyword: string;
  field: string;
  removeFilter: any;
}
const KeywordTag: React.FC<KeywordTagProps> = React.memo((props) => {
  const isDateField = props.field === resultCardFields.date;
  return (
    <Wrapper>
      <Key>
        {isDateField ? TEXT_CONFIG['Last modified date'] : props.keyword}
      </Key>

      <CloseIconWrapper
        onClick={() => {
          props.removeFilter(props.field, props.keyword);
        }}
      >
        <img src={CloseIcon} alt="close tag" width={6} height={6} />
      </CloseIconWrapper>
    </Wrapper>
  );
});
