// TODO: translate en to nl

export const TRANSLATION_TEXT = {
  'Sign in': 'Log in',
  'Hi,': 'Hallo,',
  'Dark Mode:': 'Donkere Modus:',
  'Help center': 'Klantenservice',
  'About us': 'Over ons',
  'Log Out': 'Log uit',
  'Export selected': 'Exporteer selectie',
  Filter: 'Filter',
  'Creation date': 'Aanmaakdatum',
  'Last modified date': 'Laatst gewijzigd datum',
  Modified: 'Gewijzigd',
  'No matching options': 'Geen opties',
  Category: 'Categorie',
  'Manual tagging': 'Handmatige annotatie',
  'Added tags': 'Toegevoegde annotaties',
  'Clear all': 'Wis alles',
  Cancel: 'Annuleer',
  Confirm: 'Bevestig',
  'Load more': 'Laad meer',
  Download: 'Openen',
  English: 'Engels',
  'Send feedback': 'Geef feedback',
  'Privacy Terms': 'Privacy Voorwaarden',
  Preview: 'Voorbeeld',
  Versions: 'Versies',
  'version(s) for the selected document.':
    'versie(s) voor het geselecteerde document.',
  'No results found': 'Geen resultaten gevonden',
  for: 'voor',
  'What are you looking for?': 'Waar ben je naar op zoek?',
  'result(s)': 'resultaten',
  of: 'van',
  'Deselect all': 'Deselecteer alles',
  'Select all': 'Selecteer alles',
  'Select Mode': 'Selectie Modus',
  More: 'Meer',
  'Forbidden!': 'Geweigerd!',
  'Server Error!': 'Server Foutmelding!',
  Author: 'Auteur',
  'File Type': 'Bestandstype',
  'File Path': 'Bestandslocatie',
  Project: 'Project',
  'DCL Code': 'DCL Code',
  Werkprogramma: 'Werkprogramma',
  Dossierhouder: 'Dossierhouder',
  DossierCode: 'Dossier-code',
  Archiveringscode: 'Archiveringscode',
  'Related topics': 'Gerelateerde onderwerpen',
  Pages: "Pagina's",
  'File type': 'Bestandstype',
  Keywords: 'Trefwoorden',
  'Read My Email': 'Lees mijn e-mail',
  'Created by': 'Gemaakt door',
  on: 'op',
  'Modified by': 'Gewijzigd door',
  'Sent by': 'Verstuurd door',
  'Sent to': 'Verstuurd aan',
  Aangemaakt: 'Aangemaakt',
  Gewijzigd: 'Gewijzigd',
  'Document annotation success': 'Annotated the document successfully',
  'Document annotation error': 'Annotated the document failed',
};

export const TEXT_CONFIG = (window as any)._env_?.TEXT_CONFIG
  ? JSON.parse((window as any)._env_?.TEXT_CONFIG || '{}')
  : TRANSLATION_TEXT;
