import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {xor} from 'lodash';

interface ResultSelectionState {
  selectionMode: boolean;
  selection: string[];
  selectAll: boolean;
}

const initialState: ResultSelectionState = {
  selectionMode: false,
  selectAll: false,
  selection: [],
};

const resultSelectionSlice = createSlice({
  name: 'resultSelection',
  initialState,
  reducers: {
    toggleSelectionMode: (state, action: PayloadAction<boolean | undefined>) =>
      state.selectionMode === true || action.payload === false
        ? initialState
        : {
            ...state,
            selectionMode: !state.selectionMode,
          },
    toggleSelectionResult: (state, action: PayloadAction<string>) => {
      state.selection = xor(state.selection, [action.payload]);
    },
    toggleSelectAllResults: (state) => ({
      selectionMode: true,
      selectAll: state.selection.length ? false : !state.selectAll,
      selection: [],
    }),
  },
});

export const {
  toggleSelectionMode,
  toggleSelectionResult,
  toggleSelectAllResults,
} = resultSelectionSlice.actions;

export const resultSelectionReducer = resultSelectionSlice.reducer;
