import {throttle} from 'lodash';
import {useEffect, useState} from 'react';

export const useWindowSize = () => {
  const [size, setSize] = useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line
  }, []);

  const updateSize = throttle(
    () =>
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    500,
    {
      leading: false,
      trailing: true,
    },
  );

  return [size, setSize];
};
