import React from 'react';
import {logo, ydigitalLogo} from 'helpers/getConfigs';

const LogoComponent = ({styles}: {styles?: React.CSSProperties}) => {
  return (
    <img
      style={styles}
      height={60}
      src={logo}
      alt="Logo"
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        // If the image fails to load, show ydigital logo
        const target = e.target as HTMLImageElement;
        target.src = ydigitalLogo;
      }}
    />
  );
};

export default LogoComponent;
