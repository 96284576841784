import {withSearch, Sorting as SearchUISorting} from '@elastic/react-search-ui';
import React from 'react';
import Select from 'react-select';
import {sortingOptions} from '../../../helpers/getConfigs';

const CustomSorting = (props: any) => {
  const {className, label, onChange, options, value, ...rest} = props;
  const selectedValue = value;

  const selectedOption = selectedValue
    ? options.find((option: {value: any}) => option.value === selectedValue)
    : null;

  return (
    <div {...rest}>
      <Select
        value={selectedOption}
        onChange={(event) => onChange(event.value)}
        options={options}
        isSearchable={false}
        styles={{
          dropdownIndicator: () => ({}),
          indicatorSeparator: () => ({display: 'none'}),
          option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
              ...styles,
              fontSize: 14,
              fontFamily: 'Lato',
              ':hover': {
                backgroundColor: isSelected
                  ? 'var(--primary-light)'
                  : 'rgba(24, 144, 255, 0.05)',
              },
              backgroundColor: isSelected ? 'var(--primary-light)' : '',
            };
          },
          control: (styles) => ({
            ...styles,
            width: 110,
            fontSize: 14,
            lineHeight: '14px',
            height: 40,
            color: 'var(--secondary-dark)',
            borderColor: 'var(--secondary-light)',
            ':hover': {
              borderColor: 'var(--primary)',
            },
            borderRadius: 5,
            '& > div:nth-of-type(1)': {
              padding: '0 8px',
            },
            '& > div:nth-of-type(2)': {
              paddingRight: 4,
            },
          }),
        }}
      />
    </div>
  );
};

const Sorting = withSearch(({wasSearched}) => ({wasSearched}))((props) => {
  const {wasSearched} = props;

  if (wasSearched) {
    return null;
  }

  return <SearchUISorting view={CustomSorting} sortOptions={sortingOptions} />;
});

export default Sorting;
