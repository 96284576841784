import {useKeycloak} from '@react-keycloak/web';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {getDocsByVersion} from '../../../helpers/middleware';
import {useAppSelector} from '../../../reducers';
import {setResultsByVersion} from '../../../reducers/versionSlice';
import {
  ResultSection,
  ResultSectionWithResults,
} from '../result-grids/ResultSection';

const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  background: white;
  padding-top: 70px;
  @media screen and (max-height: 900px) {
    padding-top: 60px;
  }
`;

export const GridView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setResultsByVersion({
        results: [],
        docId: '',
      }),
    );
  }, [dispatch]);
  return (
    <BodyContainer>
      <ResultSectionWithResults />
    </BodyContainer>
  );
};

export const GridViewWithVersion = ({match}) => {
  const results = useAppSelector((state) => state.versionFilter.results);
  const dispatch = useDispatch();
  const {keycloak} = useKeycloak();
  useEffect(() => {
    getDocsByVersion(
      match.params.docId,
      (resResults: any[]) => {
        dispatch(
          setResultsByVersion({
            results: resResults,
            searchTerm: match.params.query,
            docId: match.params.docId,
          }),
        );
      },
      keycloak?.token,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, match]);

  return (
    <BodyContainer>
      <ResultSection results={results} version />
    </BodyContainer>
  );
};
