import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

export const KEYCLOAK_HOSTNAME = window._env_?.KEYCLOAK_HOSTNAME + '/auth';

export const KEYCLOAK_CLIENT_ID =
  window._env_?.KEYCLOAK_SEARCH_FRONTEND_CLIENT_ID;

const keycloak = Keycloak({
  realm: 'search',
  url: KEYCLOAK_HOSTNAME,
  clientId: KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required',
  promiseType: 'native',
  checkLoginIframe: false,
  pkceMethod: 'S256',
});

export default keycloak;
