import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {isMobileOnly} from 'react-device-detect';
import {includes} from 'lodash';
import {
  convertUnicodeToSpace,
  convertWhiteSpaceToBr,
  getAllFieldValues,
  getFirstFieldValue,
} from '../../../helpers/view.helper';
import {Heading1, Heading3, Text} from '../../../components/basic/Text';
import {CardActions} from './CardActions';
import {TextRow} from '../../../components/basic/SourceTextRow';
import {ResultTags} from '../../../components/tags';
import {openPreview} from '../../../reducers/previewSlice';
import {useAppSelector} from '../../../reducers';
import {toggleSelectionResult} from '../../../reducers/resultSelectionSlice';
import {Button} from '../../../components/basic/Button';
import {TEXT_CONFIG} from '../../../translations/text';
import {FILETYPE_ICON, getIconFromType} from 'helpers/getConfigs';

const Wrapper = styled.li<{
  selectionMode?: boolean;
  selected?: boolean;
  hasIcon?: boolean;
}>`
  display: grid;
  flex-direction: column;
  list-style: none;
  padding: 5px;
  background: white;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.selected ? 'var(--primary)' : 'var(--secondary-light)')};
  border-radius: 10px;
  position: relative;
  ${(props) =>
    props.hasIcon
      ? `grid-template-columns: 64px minmax(0,1fr) minmax(0,1.5fr);`
      : ''};
  ${(props) =>
    props.selectionMode
      ? `
    user-select: none;
    cursor: pointer;
  `
      : ''}
  :hover {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;
const ContentWrapper = styled.div<{showImage?: boolean}>`
  display: grid;
  height: 100px;
  padding: 5px 0 5px;
  display: grid;
  grid-template-columns: ${(props) => (props.showImage ? '65px auto' : 'none')};
  grid-column-gap: 4px;
`;
const InlineWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  // height: 25px;
  margin-bottom: 2px;
  align-items: center;
`;
const ImageWrapper = styled.div<{imgUrl?: string}>`
  width: 65px;
  height: 90px;
  border-radius: 5px;
  margin: 5px 10px 5px 0;
  background-image: url(imgUrl);
  background-color: var(--secondary-light);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const FilePath = styled.div`
  margin-top: 4px;
`;

const Content = styled.p`
  color: var(--secondary-dark);
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const DescriptionWrapper = styled.div`
  // margin-left: auto;
  padding-left: 24px;
  // padding-right: 32px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 32px;
`;

const TextContainer = styled.div`
  margin: auto 0;
`;
export const CustomResultCard = (props) => {
  return (
    <>
      <WrappedCardComponent {...props} />
    </>
  );
};

const WrappedCardComponent = React.memo((props: any) => {
  const {selectionMode, selection, selectAll} = useAppSelector(
    (state) => state.resultSelection,
  );
  const dispatch = useDispatch();
  const {result} = props;
  const {
    titleValue,
    teaserValue,
    sourceValue,
    imgUrl,
    downloadUrlValue,
    fileTypeValue,
    filePathValue,
    authorValue,
    authorModifiedValue,
    createDateValue,
    lastModifiedtimeValue,
    recipientValue,
    folderValue,
    numCodeValue,
    descCodeValue,
  } = getAllFieldValues(result);
  const resultId = getFirstFieldValue(result.id);
  const selected = includes(selection, resultId) !== selectAll;

  // let openPreviewTimeout: any;
  // let changeLoadingCursorTimeout: any;

  // const onMouseEnter = () => {
  //   if (selectionMode === true) {
  //     return;
  //   }
  //   changeLoadingCursorTimeout = setTimeout(() => {
  //     document.body.style.cursor = 'wait';
  //   }, 300);

  //   openPreviewTimeout = setTimeout(
  //     () =>
  //       dispatch(
  //         openPreview({
  //           previewType: 'quick',
  //           result,
  //         }),
  //       ),
  //     1000,
  //   );
  // };
  // const onMouseLeave = () => {
  //   clearTimeout(openPreviewTimeout);
  //   clearTimeout(changeLoadingCursorTimeout);
  //   document.body.style.cursor = '';
  // };

  const handleSelectResult = () => {
    if (selectionMode === false) {
      return;
    }
    dispatch(toggleSelectionResult(resultId));
  };
  const FileTypeIcon = getIconFromType(fileTypeValue, FILETYPE_ICON);
  /* tslint:disable:no-string-literal */
  const createdDate =
    fileTypeValue === 'E-mail'
      ? `${TEXT_CONFIG['Sent by']} ${authorValue || ''} ${TEXT_CONFIG['on']} ${
          lastModifiedtimeValue || ''
        }`
      : `${TEXT_CONFIG['Created by']} ${authorValue || ''} ${
          TEXT_CONFIG['on']
        } ${createDateValue || ''}`;
  const lastModifiedDate =
    (fileTypeValue === 'E-mail'
      ? // `${TEXT_CONFIG['Sent to']} ${
        //     (recipientValue as string[]).join(', ') || ''
        //   }`
        ''
      : `${TEXT_CONFIG['Modified by']} ${
          authorModifiedValue || authorValue || ''
        }`) +
    (lastModifiedtimeValue && fileTypeValue !== 'E-mail'
      ? /* tslint:disable:no-string-literal */
        ` ${TEXT_CONFIG['on']} ${lastModifiedtimeValue || ''}`
      : '');
  return (
    <Wrapper
      selectionMode={selectionMode}
      hasIcon={!!FileTypeIcon}
      selected={selected}
      onClick={handleSelectResult}
      className={selected ? 'result-card-selected' : ''}
    >
      {FileTypeIcon && (
        // <div style={{margin: 'auto'}}>

        <img src={FileTypeIcon} alt="fileType" width={28} height={28} />
        // </div>
      )}
      <div
        onClick={() =>
          selectionMode || isMobileOnly
            ? undefined
            : dispatch(
                openPreview({
                  previewType: 'quick',
                  result,
                }),
              )
        }
      >
        <TitleWrapper>
          <Text
            dangerouslySetInnerHTML={{
              __html: convertWhiteSpaceToBr(titleValue),
            }}
            className="text-overflow result result-title"
            style={{
              cursor: 'pointer',
              fontWeight: '700',
              color: 'black',
              fontSize: 12,
              lineHeight: '12px',
            }}
          />
          {/* <CardActions
            downloadUrl={sourceValue}
            result={result}
            isHide={fileTypeValue === 'E-mail'}
            // clearQuickPreview={onMouseLeave}
          /> */}
        </TitleWrapper>
        <FilePath>
          <Text>
            {convertUnicodeToSpace(
              fileTypeValue === 'E-mail' ? folderValue : filePathValue,
            )
              ?.replaceAll(/\/{1,2}/g, ' > ')
              .replaceAll(': > ', '://') || ''}
          </Text>
        </FilePath>
        {/* <ContentWrapper showImage={Boolean(imgUrl)}>
          <ImageWrapper
            imgUrl={imgUrl}
            className={'result-preview-img ' + (imgUrl ? '' : 'd-none')}
          />
          <Content
            onClick={() =>
              selectionMode || isMobileOnly
                ? undefined
                : dispatch(
                    openPreview({
                      previewType: 'quick',
                      result,
                    }),
                  )
            }
            style={{
              cursor: 'pointer',
            }}
            dangerouslySetInnerHTML={{
              __html: convertWhiteSpaceToBr(teaserValue),
            }}
            className="result-content"
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
          />
        </ContentWrapper> */}
        {/* <SourceTextRow name="Source" value={sourceValue} /> */}
        {/* <InlineWrapper>
          <TextRow text={createdDate} />
          <TextRow text={lastModifiedDate} />
          <ResultTags result={result} cardResult />
          <Button
            variant="outline"
            color="#E4AA39"
            style={{
              marginLeft: 'auto',
              height: '25px',
            }}
            onClick={() => window.open(downloadUrlValue, '_blank')}
          >
            <Text>{TEXT_CONFIG.Download}</Text>
          </Button>
        </InlineWrapper> */}
      </div>
      <DescriptionWrapper>
        <TextContainer>
          <Text style={{lineHeight: '14px', fontSize: 10}}>{createdDate}</Text>
        </TextContainer>
        <TextContainer>
          <Text style={{lineHeight: '14px', fontSize: 10}}>
            {lastModifiedDate}
          </Text>
        </TextContainer>
        <TextContainer>
          <Text className="tooltip" style={{lineHeight: '14px', fontSize: 10}}>
            {numCodeValue || ''}
            {!!descCodeValue && (
              <div className="tooltiptext">{descCodeValue || ''}</div>
            )}
          </Text>
        </TextContainer>
        <div style={{height: '100%'}}>
          <CardActions
            downloadUrl={sourceValue}
            result={result}
            isHide={fileTypeValue === 'E-mail'}
            // clearQuickPreview={onMouseLeave}
          />
        </div>
      </DescriptionWrapper>
    </Wrapper>
  );
});
