import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withSearch, Result } from '@elastic/react-search-ui';
import { CustomResultCard } from './CustomResultCard';
import { isMobileOnly } from 'react-device-detect';

import { LoadingComponent } from 'components/basic/Loading';
import { TEXT_CONFIG } from 'translations/text';
import { StrongText, Text } from 'components/basic/Text';

const MainContainer = styled.div`
  flex-grow: 1;
  padding: 56px ${isMobileOnly ? 12 : 30}px 0;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 2px;
  padding-left: 28px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 32px;
`;
const GridViewWrapper = styled.div<{ count: number }>`
  display: grid;
  /* grid-template-columns: repeat(
    auto-fit,
    ${(props) => (props.count === 1 ? '600px' : 'minmax(500px, 1fr)')}
  ); */
  grid-gap: 8px;
  padding-bottom: 30px;

  /* @media screen and (max-width: 414px) {
    grid-template-columns: none !important;
  }
  @media screen and (max-width: 1244px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  @media screen and (min-width: 1244px) and (max-width: 1669px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  } */
`;

const DescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 64px minmax(0, 1fr) minmax(0, 1.5fr);
`;
const TextContainer = styled.div`
  margin-top: 48px;
  text-align: center;
`;
const SCROLL_PADDING = 100;
export const ResultSection = (props: {
  results: any[];
  version?: boolean;
  setCurrent?: any;
  current?: number;
  totalResults?: number;
  resultsPerPage?: number;
  isLoading?: boolean;
  searchTerm?: any;
  filters?: any;
  facets?: any;
  sort?: any;
  error?: any;
}) => {
  const scrollRef = useRef<boolean>(false);
  const [results, setResults] = useState<any>([]);

  useEffect(() => {
    scrollRef.current = false;
    if (props.current === 1) {
      setResults([...props.results]);
    } else {
      setResults([...results, ...props.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.results)]);

  // fetch more results when elementHeight < windowHeight (to make sure infinite scroll will trigger)
  useEffect(() => {
    const scrollHeight = document.documentElement
      ? document.documentElement.scrollHeight
      : document.body.scrollHeight;
    const totalPages = Math.ceil(
      (props?.totalResults || 1) / (props?.resultsPerPage || 1),
    );
    if (
      results?.length > 0 &&
      results.length < (props?.totalResults || 0) &&
      scrollHeight <= window.innerHeight &&
      (props?.current || 1) < totalPages &&
      scrollRef.current === false
    ) {
      scrollRef.current = true;
      props.setCurrent((props.current || 1) + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.length]);

  useEffect(() => {
    const totalPages = Math.ceil(
      (props?.totalResults || 1) / (props?.resultsPerPage || 1),
    );
    const handleScroll = () => {
      const scrollTop = document.documentElement
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
      const scrollHeight = document.documentElement
        ? document.documentElement.scrollHeight
        : document.body.scrollHeight;
      if (
        Math.ceil(scrollTop + window.innerHeight) >=
        scrollHeight - SCROLL_PADDING &&
        !props?.isLoading &&
        (props?.current || 1) < totalPages &&
        results.length < (props?.totalResults || 0)
      ) {
        if (props.setCurrent && scrollRef.current === false) {
          scrollRef.current = true;
          props.setCurrent((props.current || 1) + 1);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props, props?.isLoading, results.length]);
  return (
    <MainContainer>
      {results?.length ? (
        <DescriptionContainer>
          <div></div>
          <div></div>
          <DescriptionWrapper>
            <StrongText style={{ color: 'var(--primary)', fontSize: 12 }}>
              {TEXT_CONFIG.Aangemaakt}
            </StrongText>
            <StrongText style={{ color: 'var(--primary)', fontSize: 12 }}>
              {TEXT_CONFIG.Gewijzigd}
            </StrongText>
            <StrongText style={{ color: 'var(--primary)', fontSize: 12 }}>
              {TEXT_CONFIG.DossierCode}
            </StrongText>
            <div></div>
          </DescriptionWrapper>
        </DescriptionContainer>
      ) : null}
      <GridViewWrapper count={results.length}>
        {results?.length
          ? results.map((result, index) =>
            result ? (
              <Result
                key={index}
                result={(result as any)?.data || result}
                view={CustomResultCard}
              />
            ) : null,
          )
          : !props?.isLoading && (
            <TextContainer>
              <Text className="result">
                {TEXT_CONFIG['No results found']}
              </Text>
            </TextContainer>
          )}
      </GridViewWrapper>
      {/* {props.version ? null : <Paging />} */}
      {props?.isLoading && !props?.error && <LoadingComponent />}
    </MainContainer>
  );
};

export const ResultSectionWithResults = withSearch(
  ({
    results,
    setCurrent,
    current,
    totalResults,
    resultsPerPage,
    isLoading,
    searchTerm,
    filters,
    facets,
    sort,
    error,
  }) => ({
    results,
    current,
    totalResults,
    setCurrent,
    resultsPerPage,
    isLoading,
    searchTerm,
    filters,
    facets,
    sort,
    error,
  }),
)(ResultSection);
