import React from 'react';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { ViewProps } from '../../../types/view.types';
import PageInfo from './PageInfo';
import Filters from './Filters';
import DisplayMode from './DisplayMode';
import SelectionMode from './SelectionMode';
import Sorting from './Sorting';
import Tags from './Tags';

const Container = styled.div<ViewProps>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 70px;
  right: 0;
  left: 0;
  width: 100%;
  margin-left: 0px;
  padding: 8px ${isMobileOnly ? 12 : 30}px;
  background: white;
  z-index: 2;
  transition: all 0.3s ease;
  border-bottom: 1px solid var(--secondary-light);

  @media screen and (max-height: 900px) {
    top: 60px;
  }
`;

const Part = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

export const ResultHeader = () => {
  return (
    <Container>
      <Part>
        <PageInfo />
        <Tags />
      </Part>
      <Part>
        <SelectionMode />

        <Sorting />

        <DisplayMode />

        <Filters />
      </Part>
    </Container>
  );
};
