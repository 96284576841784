import React from 'react';
import styled from 'styled-components';
import {PreviewContent} from '../../../components/result-preview';
import {getFieldValue} from '../../../helpers/view.helper';

const SlideWrapper = styled.div`
  position: fixed;
  top: 165px;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translateX(-50%);
  z-index: 9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff;
  max-width: 950px;
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  @media screen and (max-height: 900px) {
    top: 135px;
  }
`;
export const ResultSlidePreview = ({results, resultId}) => {
  const curResult = results.find(
    (result) => getFieldValue(result?.id) === resultId,
  );

  if (curResult === undefined) {
    return null;
  }

  return (
    <SlideWrapper>
      <PreviewContent result={curResult} slidePreview={true} />
    </SlideWrapper>
  );
};
