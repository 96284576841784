import {includes} from 'lodash';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

const checkIsGridView = (pathName: string) =>
  includes(pathName, '/search/tl') === false &&
  includes(pathName, '/search/v/tl') === false;

export const useViewCheck = () => {
  const history = useHistory();
  const [isGridView, setIsGridView] = useState(
    checkIsGridView(history.location.pathname),
  );

  useEffect(() => {
    history.listen(({pathname}) => {
      setIsGridView(checkIsGridView(pathname));
    });
  });

  return [isGridView, setIsGridView];
};
