import React from 'react';
import styled from 'styled-components';
import {WithSearch} from '@elastic/react-search-ui';
import {ReactComponent as DownloadIcon} from '../../assets/images/ic-download-light.svg';
import {useAppSelector} from '../../reducers';
import {Text} from './Text';
import {exportDocumentOverview} from '../../helpers/middleware';
import {TEXT_CONFIG} from 'translations/text';
import {useKeycloak} from '@react-keycloak/web';

const Wrapper = styled.button<{variant?: ButtonVariant}>`
  min-width: 105px;
  height: 40px;
  border-radius: 5px;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;
  user-select: none;
  cursor: pointer;
  &:active p {
    color: #ffffff !important;
  }
  & p {
    font-family: Lato;
  }
  ${(props) =>
    props.variant === 'outline' || props.variant === undefined
      ? `
        color: var(--secondary);
        border: 1px solid rgba(0, 0, 0, 0.1);
        & p {
          color: var(--secondary);
        }
        &:hover {
          border-color: var(--primary-dark);
        }
        &:hover p {
          color: var(--primary-dark)
        }
        &:active {
          border-color: var(--primary-light);
          background: var(--primary-light)
        }
      `
      : props.variant === 'primary'
      ? `
        background: var(--primary);
        &:hover {
          background: var(--primary-dark);
        }
        &:active {
          background: var(--primary-light);
        }
        & p {
          color: #ffffff;
        }
      `
      : props.variant === 'text'
      ? `
        background: none !important;
        min-width: auto;
        height: auto;
        & p {
          color:  var(--primary) !important;
        }
    `
      : `
        background: var(--danger);
        &:hover {
          background: var(--danger-dark);
        }
        &:active {
          background: var(--danger-light);
        }
        & p {
          color: #ffffff;
        }
    `}
`;

type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'text';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
}
export const Button: React.FC<ButtonProps> = React.memo(
  ({children, ...props}) => {
    return <Wrapper {...props}>{children}</Wrapper>;
  },
);

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element;
  selectionMode?: boolean;
}
const StyledButton = styled.button<{selectionMode?: boolean}>`
  border: none;
  min-height: 26px;
  min-width: 26px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: ${(props) =>
      props.selectionMode ? 'transparent' : 'lightgray'};
  }
`;

export const IconButton: React.FC<IconButtonProps> = React.memo(
  ({icon, ...props}) => {
    return <StyledButton {...props}>{icon}</StyledButton>;
  },
);

const FloatingWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 99;
`;
const TotalWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  color: #fff;
`;
export const ExportButton = () => {
  const {keycloak} = useKeycloak();
  const selection = useAppSelector((state) => state.resultSelection.selection);
  const selectAll = useAppSelector((state) => state.resultSelection.selectAll);
  const versionResults = useAppSelector((state) => state.versionFilter.results);

  if (selection.length === 0 && selectAll === false) {
    return null;
  }

  return (
    <WithSearch
      mapContextToProps={({
        totalResults,
        filters,
        searchTerm,
        sortField,
        sortDirection,
        current,
        resultsPerPage,
      }) => ({
        totalResults,
        filters,
        searchTerm,
        sortField,
        sortDirection,
        current,
        resultsPerPage,
      })}
    >
      {({totalResults, ...queryParams}) => {
        const count = selectAll
          ? versionResults.length
            ? versionResults.length - selection.length
            : totalResults - selection.length
          : selection.length;

        const exportResults = () => {
          exportDocumentOverview(
            {...queryParams, selectAll, selection},
            keycloak?.token,
          );
        };

        return (
          <FloatingWrapper>
            <Button
              variant="primary"
              style={{
                padding: '0 12px',
                boxShadow: '0px 10px 25px -15px #1890FF',
              }}
              onClick={exportResults}
            >
              <DownloadIcon style={{margin: '2px 6px 0 0'}} />
              <Text>{TEXT_CONFIG['Export selected']}</Text>
              <TotalWrapper>[{count <= 9999 ? count : '9999+'}]</TotalWrapper>
            </Button>
          </FloatingWrapper>
        );
      }}
    </WithSearch>
  );
};
