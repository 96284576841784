import styled from 'styled-components';
import React from 'react';
import {TEXT_CONFIG} from 'translations/text';
import {withSearch} from '@elastic/react-search-ui';
import {Keywords, Tag} from '../../../components/tags';

const KeywordRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  margin-right: 5px;
`;

const Tags = withSearch(({filters, removeFilter, clearFilters}) => ({
  filters,
  removeFilter,
  clearFilters,
}))((props) => {
  const {filters, removeFilter, clearFilters} = props;

  if (filters.length === 0) {
    return null;
  }

  return (
    <>
      <KeywordRow>
        {filters.map((filter) => (
          <Keywords
            key={filter.field}
            filter={filter}
            removeFilter={removeFilter}
          />
        ))}
      </KeywordRow>

      {filters.length ? (
        <Tag onClick={() => clearFilters()}>{TEXT_CONFIG['Clear all']}</Tag>
      ) : null}
    </>
  );
});

export default Tags;
