import './search-box.css';
import React from 'react';
import {SearchBox as SuiSearchBox, withSearch} from '@elastic/react-search-ui';
import {getFieldValue, highlightSubtext} from '../../helpers/view.helper';
import {resultCardFields} from '../../helpers/getConfigs';
import {renderCustomSearchInput} from './CustomSearchInput';

const SearchBox = (props: {home?: boolean; setSearchTerm}) => {
  const {home, setSearchTerm} = props;
  // const transformSuggestion = (suggestion) => {
  //   return {
  //     ...suggestion,
  //     title: suggestion?.title?.replace(/<em>/g, '').replace(/<\/em>/g, ''),
  //   };
  // };
  return (
    <SuiSearchBox
      // autocompleteSuggestions
      // transformSuggestion={transformSuggestion}
      // autocompleteResults={{
      //   titleField: resultCardFields.title,
      //   urlField: resultCardFields.source,
      //   sectionTitle: 'Resultaten',
      // }}
      // autocompleteView={({
      //   autocompletedResults,
      //   getInputProps,
      //   autocompletedSuggestions,
      //   autocompletedSuggestionsCount,
      // }) => {
      //   // const {value} = getInputProps();
      //   console.log({autocompletedSuggestions, autocompletedResults});
      //   return (
      //     <div
      //       style={{padding: 16, maxHeight: 450, overflowY: 'scroll'}}
      //       className="sui-search-box__autocomplete-container"
      //     >
      //       {autocompletedSuggestions?.results?.map((e, i) => (
      //         <div key={i}>
      //           <div style={{margin: '8px 0'}}>
      //             <strong style={{fontSize: 20}}>
      //               {getFieldValue(e?.title) || e?.title}
      //             </strong>
      //           </div>

      //           {e?.results?.map((result, index) => {
      //             return (
      //               <div
      //                 key={index}
      //                 dangerouslySetInnerHTML={{
      //                   __html: (getFieldValue(result?.title) || '') as string,
      //                 }}
      //                 onClick={() =>
      //                   (window.location.href =
      //                     '/search?q=' +
      //                       (getFieldValue(result?.title) as string)
      //                         ?.replaceAll('<em>', '')
      //                         ?.replaceAll('</em>', '') || '')
      //                 }
      //                 className="suggestions flex"
      //               ></div>
      //             );
      //           })}
      //         </div>
      //       ))}
      //     </div>
      //   );
      // }}
      // autocompleteMinimumCharacters={1}
      autocompleteMinimumCharacters={1}
      inputView={renderCustomSearchInput(setSearchTerm, home)}
      onSubmit={(searchTerm) => {
        // Prevent empty searching
        searchTerm = searchTerm.trim();
        if (searchTerm !== '') {
          window.location.href = '/search?q=' + searchTerm;
        }
      }}
      onSelectAutocomplete={(selection, options) => {
        // Search with title-field value
        const newSearchTerm =
          selection.suggestion ||
          getFieldValue(selection[options.autocompleteResults.titleField]) ||
          '';
        window.location.href = '/search?q=' + newSearchTerm;
      }}
    />
  );
};

export default withSearch(({setSearchTerm}) => ({setSearchTerm}))(SearchBox);
