import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {previewReducer} from './previewSlice';
import {resultSelectionReducer} from './resultSelectionSlice';
import {authReducer} from './authSlice';
import {taggingReducer} from '../components/manual-tagging/taggingSlice';
import {versionReducer} from './versionSlice';

const reducers = combineReducers({
  preview: previewReducer,
  resultSelection: resultSelectionReducer,
  auth: authReducer,
  tagging: taggingReducer,
  versionFilter: versionReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});

export const persistor = persistStore(store);

// State Type
export type RootState = ReturnType<typeof reducers>;

// Inject Type RootState on useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
