import moment from 'moment';
import {capitalize, isEqual, lowerCase} from 'lodash';
import {
  annotateCategoriesConfig,
  defaultTags,
  resultCardFields,
  tagFieldColor,
} from './getConfigs';
import {Categories, Tag} from '../components/manual-tagging/taggingSlice';

export const getFilterValueDisplay = (filterValue: any) => {
  if (filterValue === undefined || filterValue === null) return '';
  if (filterValue.hasOwnProperty('name')) return filterValue.name;
  return String(filterValue);
};

export const getNewClassName = (newClassName: any[]) => {
  if (!Array.isArray(newClassName)) return newClassName;

  return newClassName.filter((name) => name).join(' ');
};

export const appendClassName = (baseClassName: string, newClassName: any) => {
  if (!newClassName) return baseClassName || '';
  if (!baseClassName) return getNewClassName(newClassName) || '';
  return `${baseClassName} ${getNewClassName(newClassName)}`;
};

export const numberWithCommas = (n: number) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isPropsEqual = (prevProps: any, nextProps: any) => {
  // console.log(prevProps, nextProps, isEqual(prevProps, nextProps));
  return isEqual(prevProps, nextProps);
};

export const getTotalFilter = (filter: Array<{values: string[]}>) => {
  let count = 0;
  filter.forEach((el) => (count += el.values.length));
  return count;
};

export const getFieldValue = (
  field:
    | {
        raw?: string;
        snippet?: string;
      }
    | string,
): string | string[] | any => {
  return typeof field === 'string' ? field : field?.snippet || field?.raw || '';
};

export const getFirstValue = (fieldValue: string | string[]) => {
  if (typeof fieldValue === 'string') {
    return fieldValue;
  }
  return fieldValue[0] || '';
};

export const getFirstFieldValue = (field: {raw?: string; snippet?: string}) =>
  field ? getFirstValue(getFieldValue(field)) : '';

export const getAllFieldValues = (result) => {
  const {
    teaser,
    content,
    title,
    source,
    date,
    image,
    downloadUrl,
    filetype,
    filepath,
    createdDate,
    lastModifiedtime,
    author,
    authorModified,
    recipients,
    folder,
    numCode,
    descCode,
  } = resultCardFields;

  const titleValue = getFirstFieldValue(result[title]);
  const contentValue = getFirstFieldValue(result[content]);
  const teaserValue = getFirstFieldValue(result[teaser]);
  const sourceValue = getFirstFieldValue(result[source]);
  const imgUrl = getFirstFieldValue(result[image]);
  const fileTypeValue = getFirstFieldValue(result[filetype]);
  const filePathValue = getFirstFieldValue(result[filepath]);
  const downloadUrlValue = getFirstFieldValue(result[downloadUrl]);
  const authorValue = getFirstFieldValue(result[author]);
  const authorModifiedValue = getFirstFieldValue(result[authorModified]);
  const recipientValue = getFieldValue(result[recipients]);
  const folderValue = getFirstFieldValue(result[folder]);
  const createDateValue = result[createdDate]
    ? moment(getFirstFieldValue(result[createdDate])).format(
        'DD MMM, yyyy - HH:mm',
      )
    : '';
  const lastModifiedtimeValue = result[lastModifiedtime]
    ? moment(getFirstFieldValue(result[lastModifiedtime])).format(
        'DD MMM, yyyy - HH:mm',
      )
    : '';
  const formattedDate = result[date]
    ? moment(getFirstFieldValue(result[date])).format('DD MMM, yyyy - HH:mm')
    : '';

  const numCodeValue = getFirstFieldValue(result[numCode]);
  const descCodeValue = getFirstFieldValue(result[descCode]);
  return {
    titleValue,
    teaserValue,
    contentValue,
    sourceValue,
    imgUrl,
    formattedDate,
    downloadUrlValue,
    fileTypeValue,
    filePathValue,
    createDateValue,
    lastModifiedtimeValue,
    authorValue,
    authorModifiedValue,
    folderValue,
    recipientValue,
    numCodeValue,
    descCodeValue,
  };
};

export const convertVarNameToLabel = (varName: string) =>
  capitalize(lowerCase(varName));

export const jsonParseData = (input: any, defaultValue?: any) => {
  if (input) {
    try {
      return JSON.parse(input);
    } catch {
      return defaultValue;
    }
  }
  return defaultValue;
};
export const getAllAvailableTags = (
  result: any,
  customTags?: any,
  manualTags?: boolean,
) => {
  const availableTags: any[] = [];

  if (manualTags) {
    const parsedCategories: Categories | undefined = jsonParseData(
      getFirstFieldValue(result.annotation),
      undefined,
    );
    if (parsedCategories) {
      Object.entries(parsedCategories).forEach(([cateName, cateValue]) => {
        cateValue.values?.forEach((tag) => {
          availableTags.push({
            field: cateName,
            type: 'all',
            label: convertVarNameToLabel(cateName),
            color: getTagFieldColor(cateName),
            value: tag,
          });
        });
      });
    }
  } else {
    (customTags || defaultTags)?.forEach((configTag) => {
      const value = getFieldValue(result[configTag?.field]);

      if (value === '') {
        return;
      }

      if (typeof value === 'string' || typeof value === 'number') {
        return availableTags.push({
          ...configTag,
          value,
        });
      }

      if (typeof value === 'object') {
        return value?.values?.forEach((element) => {
          availableTags.push({
            ...configTag,
            value: element,
          });
        });
      }

      value.forEach((item) => {
        availableTags.push({
          ...configTag,
          value: item,
        });
      });
    });
  }
  return availableTags;
};

export const getTagFieldColor = (fieldName: string) => tagFieldColor[fieldName];

export const getCategoryObjectFromSelectedTags = (selectedTags: Tag[]) => {
  const categoriesObj = {};
  console.log(selectedTags);
  selectedTags.forEach((tag) => {
    const newTagList = categoriesObj[tag.category]?.values || [];
    newTagList.push(tag.tag);
    categoriesObj[tag.category] = {
      ...categoriesObj[tag.category],
      values: newTagList,
    };
  });

  // push empty values to unselected tag
  annotatesCategoriesKey?.forEach((tag) => {
    if (tag?.field && !categoriesObj.hasOwnProperty(tag?.field)) {
      categoriesObj[tag?.field] = {
        values: [],
      };
    }
  });
  return categoriesObj;
};

export const convertWhiteSpaceToBr = (text?: string) => {
  return text?.replace(/\n/g, '<br>') || '';
};

export const currentPageRegex = /(current=)\w+&/i;

export const convertUnicodeToSpace = (text?: string) => {
  return text?.replaceAll('%20', ' ') || '';
};

export const highlightSubtext = (text: string, subtext: string) => {
  const highlightedText = text.replace(
    new RegExp(`(${subtext})`, 'gi'),
    '<strong>$1</strong>',
  );
  return highlightedText;
};

export const convertArrayToObject = (array: object[] | any) => {
  // Initialize an empty object to store the result
  const mergedObject = {};

  // Iterate through the array and merge each object into the mergedObject
  array.forEach((obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        mergedObject[key] = obj[key];
      }
    }
  });
  return mergedObject;
};

const getKeysOfObject = (originalObject: object | any) => {
  // Extract the keys and create an array of objects
  if (!originalObject) return undefined;
  const keysArray = Object.keys(originalObject);
  const resultArray = keysArray.map((key) => {
    return {
      field: key,
      type: 'any',
      label: '',
    };
  });
  return resultArray;
};
export const annotatesCategoriesKey = getKeysOfObject(
  annotateCategoriesConfig.annotation,
);

export const getDocumentAnnotated = (result: any, customTags?: any) => {
  const annotation = [] as any;
  customTags?.forEach((configTag) => {
    const value = getFieldValue(result[configTag?.field]);

    if (value === '') {
      return;
    }
    annotation.push({[configTag?.field]: value});
  });
  return annotation;
};
