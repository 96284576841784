import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '.';

interface UserInfo {
  email_verified?: boolean;
  preferred_username?: string;
  sub?: string;
  email?: string;
}
interface Auth {
  userInfo?: UserInfo;
  authenticated: boolean;
}
const initialState: Auth = {
  authenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setuserProfile: (state, action) => {
      return action.payload;
    },
  },
});

export const {setuserProfile} = authSlice.actions;

export const selectUserInfo = (state: RootState) => state.auth;
export const authReducer = authSlice.reducer;
