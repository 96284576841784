import React from 'react';
import styled from 'styled-components';
import {TEXT_CONFIG} from 'translations/text';
import {ReactComponent as DownIcon} from '../../../assets/images/ic-down.svg';
import {ReactComponent as FilterIcon} from '../../../assets/images/ic-filter.svg';
import {
  Button as ReactAriaButton,
  Popover as ReactAriaPopover,
  DialogTrigger as ReactAriaDialogTrigger,
} from 'react-aria-components';
import TimeRange from 'components/filter/TimeRange';
import {FacetList} from 'components/filter/FacetList';

const FilterTriggerWrapper = styled(ReactAriaButton)`
  appearance: none;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  user-select: none;
  & path {
    stroke-width: 2;
    stroke-opacity: 1;
  }

  padding: 5px;
`;
const FilterTriggerLabel = styled.span`
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  margin-right: 5px;
  margin-left: 5px;
`;

function FilterTrigger() {
  return (
    <FilterTriggerWrapper>
      <FilterIcon />
      <FilterTriggerLabel>{TEXT_CONFIG.Filter}</FilterTriggerLabel>
      <DownIcon />
    </FilterTriggerWrapper>
  );
}

const FilterPopover = styled(ReactAriaPopover)`
  overflow-y: auto;
  overflow-x: hidden;

  padding: 30px;

  min-width: min(360px, calc(100% - 60px));
  min-height: 440px;

  background: #fff;

  border-radius: 10px;
  border: none;

  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  .sui-facet + .sui-facet {
    margin-top: 0;
  }
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 20px;
`;

export default function Filters() {
  return (
    <ReactAriaDialogTrigger>
      <FilterTrigger />

      <FilterPopover placement="bottom">
        <FilterOptions>
          <TimeRange />

          <FacetList />
        </FilterOptions>
      </FilterPopover>
    </ReactAriaDialogTrigger>
  );
}
