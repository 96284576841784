import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {useAppSelector} from '../../../reducers';
import {selectCategory} from '../taggingSlice';
import {StrongText} from '../../basic/Text';
import {convertVarNameToLabel} from '../../../helpers/view.helper';
import {TEXT_CONFIG} from 'translations/text';

const CategoriesWrapper = styled.div`
  width: 170px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
`;
const CategoryList = styled.li`
  list-style-type: none;
  height: calc(100% - 42px);
  overflow: hidden auto;
  padding-right: 15px;
  padding-bottom: 15px;
`;
const CategoryItem = styled.ul`
  border-radius: 5px;
  height: 32px;
  line-height: 32px;
  margin: 0;
  padding: 0 5px;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  user-select: none;
  :hover {
    background: rgba(24, 144, 255, 0.1);
  }
`;
export const CategorySelection = () => {
  const categories = useAppSelector((state) => state.tagging.categories);
  const selectedCate = useAppSelector((state) => state.tagging.selectedCate);
  const dispatch = useDispatch();

  return (
    <CategoriesWrapper>
      <div className="tagging-tab-title">
        <StrongText>{TEXT_CONFIG.Category}</StrongText>
      </div>

      <CategoryList className="custom-scrollbar">
        {Object.entries(categories).map(([category, value], index) => (
          <CategoryItem
            key={index}
            className={category === selectedCate ? 'category--selected' : ''}
            onClick={() => dispatch(selectCategory(category))}
          >
            {convertVarNameToLabel(value?.label || '')}
          </CategoryItem>
        ))}
      </CategoryList>
    </CategoriesWrapper>
  );
};
