import React from 'react';
import styled from 'styled-components';
import {Avatar} from '../../components/basic/Avatar';
import {SearchBox} from '../../components/search-box';
import LogoComponent from 'components/basic/Logo';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 220px; */
`;
const LogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
`;
const ActionWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

export const HomePage = () => {
  return (
    <Container>
      <LogoWrapper>
        <LogoComponent styles={{width: 200}} />
      </LogoWrapper>
      <SearchBoxWrapper>
        <SearchBox home />
      </SearchBoxWrapper>

      <ActionWrapper>
        <Avatar />
      </ActionWrapper>
    </Container>
  );
};
