import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '.';

export interface VersionFilterState {
  results: any[];
  searchTerm?: string;
  docId: string;
}
const initialState: VersionFilterState = {
  results: [],
  docId: '',
};
const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setResultsByVersion: (state, action: PayloadAction<VersionFilterState>) => {
      state = action.payload;
      return state;
    },
  },
});

const resultsSelector = (state: RootState) => state.versionFilter.results;
const searchTermSelector = (state: RootState) => state.versionFilter.searchTerm;
export const isVersionFilterSelector = createSelector(
  resultsSelector,
  searchTermSelector,
  (results, searchTerm) => Boolean(results.length && searchTerm),
);

export const {setResultsByVersion} = versionSlice.actions;
export const versionReducer = versionSlice.reducer;
