import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    color: var(--secondary-dark);
  }
`;
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerStyles?: React.CSSProperties;
}
export const Checkbox: React.FC<Props> = ({
  label,
  containerStyles,
  ...props
}) => {
  return (
    <Wrapper style={containerStyles}>
      <input {...props} type="checkbox" id="toggle-select-all" />

      <label
        htmlFor="toggle-select-all"
        className="sui-multi-checkbox-facet__option-label"
      >
        <span className="sui-multi-checkbox-facet__input-text">{label}</span>
      </label>
    </Wrapper>
  );
};
