import {API_URL} from 'configs/app-search';
import store from '../reducers';
import {adaptExportDocumentOverviewRequest} from './requestAdapters';
import {getCategoryObjectFromSelectedTags} from './view.helper';
import {toast} from 'react-toastify';
import {TEXT_CONFIG} from 'translations/text';
import {ENGINE_NAME} from './getConfigs';

export const fetchSuggestion = async ({token, searchTerm, callback}) => {
  const response = await fetch(
    `${API_URL}/api/as/v1/engines/${ENGINE_NAME}/query_suggestion`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: searchTerm,
        size: 5,
      }),
    },
  )
    .then((res) => {
      return res.json();
    })
    .catch((error) => console.log('Fetch suggestion error', error));
  callback(response);
};
export const getManualTaggingList = async () => {
  await fetch('https://jsonplaceholder.typicode.com/todos/1', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => console.log('Fetch manual tagging error', error));
};

export const fetchEmailSetting = async ({token, email, callback}) => {
  const response = await fetch(`${API_URL}/api/as/v1/get-email-settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      username: email,
    }),
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => console.log('Fetch email setting error', error));
  callback(response);
};

export const fetchEmailAndOneDriveInfo = async ({token, userId, callback}) => {
  const response = await fetch(`${API_URL}/api/as/v1/user/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => console.log('Fetch email setting error', error));
  callback(response);
};

export const setEmailSetting = async ({token, data, callBack}) => {
  await fetch(`${API_URL}/api/as/v1/set-email-settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      callBack();
      return res.json();
    })
    .catch((error) => console.log('Set email setting error', error));
};
export const updateDocumentMetadata = async (token: string, callback: any) => {
  const {curDoc, selectedTags} = store.getState().tagging;

  await fetch(`${API_URL}/api/as/v1/annotate-document`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      Authorization: `Bearer ${token}`,
      // authorization: 'Bearer private-tsceppofbwnax2vu2e8insdw',
      // 'x-swiftype-client': 'elastic-app-search-javascript',
      // 'x-swiftype-client-version': '7.8.0',
      // 'x-swiftype-integration': 'search-ui',
      // 'x-swiftype-integration-version': '1.4.1',
    },
    body: JSON.stringify({
      id: curDoc.id,
      annotation: getCategoryObjectFromSelectedTags(selectedTags),
    }),
  })
    .then((response) => {
      if (response.ok) {
        toast.success(TEXT_CONFIG['Document annotation success']);
        callback();
        return response.json();
      }
      throw new Error('Something went wrong');
    })
    .catch((error) => {
      toast.error(TEXT_CONFIG['Document annotation error']);
      console.log('Document annotation error', error);
    });
};

export const getDocsByVersion = async (
  docId: string,
  callback: (results: any[]) => void,
  token?: string,
) => {
  const response = await fetch(
    `${API_URL}/api/as/v1/get-document-versions/${docId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((res) => {
      return res.json();
    })
    .catch((error) => console.log('Fetch document version error', error));
  callback(response?.result || []);
};

export const exportDocumentOverview = async (params, token) => {
  fetch(`${API_URL}/api/as/v1/get-topic-overview`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(adaptExportDocumentOverviewRequest(params)),
  })
    .then((res) => res.blob())
    .then((blob) => {
      if (blob.type === 'text/html') {
        return console.log('export docs overview error');
      }
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Overview.docx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
};
